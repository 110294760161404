import { ProjectMode } from '@contexts/Project.context';

/**
 * returns the job name based on the job type
 * @param {string} type The job type
 * @returns {string} Returns the job name
 */
export const getJobName = type => {
	switch (type) {
		case 'segmentation_train':
			return 'Segmentation training';
		case 'segmentation_predict':
			return 'Segmentation prediction';
		case 'object_detection_train':
			return 'Object detection training';
		case 'object_detection_predict':
			return 'Object detection prediction';
		case 'photogrammetry':
			return 'Photogrammetry';
		case 'grid_tiling':
			return 'Grid tiling';
		case 'reproject_tif':
			return 'Reprojecting TIF';
		case 'user_annotation_processing':
			return 'User annotation processing';
		case 'user_annotation_train':
			return 'User annotation training';
		case 'orthophoto_postprocessing':
			return 'Processing orthophoto';
		case 'segmentation_SAM':
			return 'Segmentation SAM';
		case 'model_evaluation':
			return 'Model evaluation';
		case 'single_image_processing':
			return 'Single image processing';

		default:
			return 'Job in progress';
	}
};

export const getNotificationInterval = mode => {
	switch (mode) {
		case ProjectMode.SINGLE_IMAGE:
			return 5000; // 5 seconds
		default:
			return 1000 * 60; // 1 minute
	}
};

export const getJobTypeClassification = type => {
	switch (type) {
		case 'photogrammetry':
		case 'grid_tiling':
		case 'reproject_tif':
		case 'orthophoto_postprocessing':
		case 'single_image_processing':
			return 'Creating Project';

		case 'segmentation_train':
		case 'object_detection_train':
		case 'user_annotation_processing':
		case 'user_annotation_train':
			return 'Training';

		case 'segmentation_predict':
		case 'object_detection_predict':
		case 'segmentation_SAM':
			return 'Detecting';

		case 'model_evaluation':
			return 'Evaluating';

		default:
			return null;
	}
};

export const extractLatestNotificationJobProgress = message => {
	let lastProgress = null;

	// Iterate over each key in the message object
	for (const key in message) {
		// Check if the key is an own property and the value is an object with a 'progress' property
		if (
			message.hasOwnProperty(key) &&
			typeof message[key] === 'object' &&
			message[key].hasOwnProperty('progress')
		) {
			// Update lastProgress with the current job's details
			lastProgress = {
				jobType: key,
				jobName: getJobName(key),
				progress: message[key].progress,
				text: message[key].text,
				classification: getJobTypeClassification(key),
			};
		}
	}

	// Check if the last job is a project creation job
	const isProjectCreationJob =
		lastProgress.classification === 'Creating Project';

	// If the job is a project creation job and is completed, override the text
	if (isProjectCreationJob && lastProgress?.progress === 100) {
		lastProgress.text = 'Project created';
	}

	return lastProgress; // Return the last progress found or null if none found
};
