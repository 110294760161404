import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';

import {
	AiOutlineFileText,
	AiOutlineMore,
	AiFillFolderOpen,
	AiOutlinePlayCircle,
	AiOutlineDownload,
	AiOutlineFilePdf,
} from 'react-icons/ai';
import { FaRegMap } from 'react-icons/fa';

import {
	archiveProject,
	orthophotoPostprocessing,
	adminGetProjectFilesDowloadUrl,
	unsetDemoProject,
} from '@api';
import { downloadProjectFiles } from '@utils/downloads';
import { ImageMode } from '@contexts/Project.context';

import ReportModal from './ReportModal';
import DetailsModal from './DetailsModal';
import OdmPostprocessModal from './OdmPostprocessModal';
import SagemakerDownloadModal from './SagemakerDownloadModal';
import DemoProjectModal from './DemoProjectModal';

const DropdownToggle = styled(Dropdown.Toggle)`
	background: none;
	padding: 0;
	font-size: 1.2em;
	:after {
		display: none;
	}
`;

const DropdownMenu = ({ data }) => {
	const iconClass = 'mt-1 mb-2 me-2';

	const { uuid, image_mode, demo_project, title } = data;

	const [showReport, setShowReport] = useState(false);
	const [showDetails, setShowDetails] = useState(null);
	const [showPostprocess, setShowPostprocess] = useState(false);
	const [showSagemaker, setShowSagemaker] = useState(false);
	const [showProjectDemo, setShowProjectDemo] = useState(false);

	const handleDelete = async () => {
		if (window.confirm('Are you sure you want to archive this project?')) {
			await archiveProject(uuid)
				.then(() => {
					// Refresh the page
					window.location.reload();
				})
				.catch(err => {
					console.error(err);
					window.alert('Failed to archive project');
				});
		}
	};

	const handleUnsetDemo = async () => {
		if (
			window.confirm(
				`Are you sure you want to unset ${title} as a demo project?`
			)
		) {
			await unsetDemoProject(uuid)
				.then(() => {
					// Refresh the page
					window.location.reload();
				})
				.catch(err => {
					console.error(err);
					window.alert('Failed to unset demo project');
				});
		}
	};

	const handleOrthophoto = async () => {
		if (
			window.confirm(
				'Are you sure you want to start orthophoto postprocessing?'
			)
		) {
			//@TODO: this need to incldue the size of the orthophoto
			await orthophotoPostprocessing(uuid)
				.then(response => {
					console.log('response', response);
					if (response?.status === 200) {
						window.alert(
							response?.data?.status ||
								'Orthophoto postprocessing started'
						);
					} else {
						window.alert(
							'Failed to start orthophoto postprocessing'
						);
					}
				})
				.catch(err => {
					console.error(err);
					window.alert('Failed to start orthophoto postprocessing');
				});
		}
	};

	const handleDownloadImages = async () => {
		window.alert('Initiating download. Be patient.');
		await downloadProjectFiles(uuid)
			.then(response => {
				window.alert(response);
			})
			.catch(err => {
				window.alert(err);
			});
	};

	const presignedUrl = async (name, path, file_name) => {
		await adminGetProjectFilesDowloadUrl(uuid, path, file_name)
			.then(response => {
				const url = Object.values(response)[0]; // Get the first value from the response object
				window.open(url, '_blank'); // Open the URL in a new tab
			})
			.catch(err => {
				console.error(err);
				window.alert(
					`Failed to get ${name}. Check the console for more information.`
				);
			});
	};

	return (
		<>
			<Dropdown>
				<DropdownToggle variant="dark">
					<AiOutlineMore />
				</DropdownToggle>
				<Dropdown.Menu variant="dark">
					<Dropdown.Item
						as="button"
						type="button"
						onClick={() => setShowReport(true)}>
						<AiOutlineFileText
							className={iconClass}
							aria-hidden="true"
						/>
						Resource report
					</Dropdown.Item>
					<Dropdown.Item as={Link} to={`/projects/${uuid}`}>
						<FaRegMap className={iconClass} aria-hidden="true" />
						View on map
					</Dropdown.Item>
					<Dropdown.Item
						as="button"
						type="button"
						onClick={() => setShowDetails('project')}>
						<AiOutlineFileText
							className={iconClass}
							aria-hidden="true"
						/>
						Details
					</Dropdown.Item>

					<Dropdown.Divider className="border-secondary" />

					<Dropdown.Item
						as="button"
						type="button"
						onClick={handleDownloadImages}>
						<AiOutlineDownload
							className={iconClass}
							aria-hidden="true"
						/>
						Images from s3
					</Dropdown.Item>

					{(image_mode === ImageMode.GENERATE_ORTHOPHOTO ||
						image_mode === ImageMode.UPLOAD_ORTHOPHOTO) && (
						<>
							<Dropdown.Item
								as="button"
								type="button"
								onClick={() =>
									presignedUrl(
										'ODM report',
										'odm_report/',
										'report.pdf'
									)
								}>
								<AiOutlineFilePdf
									className={iconClass}
									aria-hidden="true"
								/>
								ODM report
							</Dropdown.Item>

							<Dropdown.Item
								as="button"
								type="button"
								onClick={() =>
									presignedUrl('ODM log', '', 'log.json')
								}>
								<AiOutlineFileText
									className={iconClass}
									aria-hidden="true"
								/>
								ODM log
							</Dropdown.Item>
						</>
					)}

					<Dropdown.Item
						as="button"
						type="button"
						onClick={() => setShowSagemaker(true)}>
						<AiOutlineFileText
							className={iconClass}
							aria-hidden="true"
						/>
						Sagemaker files
					</Dropdown.Item>

					<Dropdown.Divider className="border-secondary" />
					<Dropdown.Item
						as="button"
						type="button"
						onClick={() => setShowProjectDemo(true)}>
						<AiOutlinePlayCircle
							className={iconClass}
							aria-hidden="true"
						/>
						{demo_project
							? 'Update demo model'
							: 'Set as demo project'}
					</Dropdown.Item>
					{demo_project && (
						<Dropdown.Item
							as="button"
							type="button"
							onClick={() => handleUnsetDemo()}>
							<AiOutlinePlayCircle
								className={iconClass}
								aria-hidden="true"
							/>
							Unset as demo project
						</Dropdown.Item>
					)}

					<Dropdown.Divider className="border-secondary" />

					{image_mode === ImageMode.GENERATE_ORTHOPHOTO && (
						<Dropdown.Item
							as="button"
							type="button"
							onClick={() => setShowPostprocess(true)}>
							<AiOutlinePlayCircle
								className={iconClass}
								aria-hidden="true"
							/>
							ODM Postprocess
						</Dropdown.Item>
					)}
					{/* <Dropdown.Item as="button" type="button" onClick={handleOrthophoto}>
                        <AiOutlinePlayCircle
                            className={iconClass}
                            aria-hidden="true"
                        />
                        Ortho Postprocess
                    </Dropdown.Item> */}
					<Dropdown.Item
						as="button"
						type="button"
						onClick={handleDelete}>
						<AiFillFolderOpen
							className={iconClass}
							aria-hidden="true"
						/>
						Archive
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>

			{showReport && (
				<ReportModal
					project_uuid={uuid}
					show={showReport}
					setShow={setShowReport}
				/>
			)}

			{showDetails && (
				<DetailsModal
					project_uuid={uuid}
					type={showDetails}
					setType={setShowDetails}
				/>
			)}

			{showPostprocess && (
				<OdmPostprocessModal
					project_uuid={uuid}
					image_mode={image_mode}
					show={showPostprocess}
					setShow={setShowPostprocess}
				/>
			)}

			{showSagemaker && (
				<SagemakerDownloadModal
					project_uuid={uuid}
					show={showSagemaker}
					setShow={setShowSagemaker}
				/>
			)}

			{showProjectDemo && (
				<DemoProjectModal
					project={data}
					show={showProjectDemo}
					setShow={setShowProjectDemo}
				/>
			)}
		</>
	);
};
export default DropdownMenu;
