import Alert from '@components/alert/Alert';
import Loader from '@components/loading/Loader';

import AlertWarningContent from './AlertWarningContent';

/**
 * Component for displaying the estimated area of the project
 *
 * @param {object} exifFootprint - result of the exif footprint calculation
 * @param {boolean} calculatingArea - whether the area is currently being calculated
 * @param {boolean} disabled - whether the component is disabled
 */
const SingleImageFootprint = ({ exifFootprint, calculatingArea, disabled }) => {
	if (disabled) return null;

	if (calculatingArea) {
		return (
			<Alert variant="dark" heading="Preprocessing files">
				<Loader
					inline
					message={
						<small>
							Duration varies based on the number of images
						</small>
					}
				/>
			</Alert>
		);
	}

	const {
		footprint_area: estimatedArea,
		warning,
		warning_response: warningResponse,
	} = exifFootprint || {};

	if (warning) {
		return (
			<Alert variant="warning" heading="Warning">
				<AlertWarningContent
					warning={warning}
					warningResponse={warningResponse}
				/>
			</Alert>
		);
	}

	if (estimatedArea) {
		return (
			<Alert variant="success" heading="Preprocessing files completed">
				<p>Click 'Create Project' to continue</p>
			</Alert>
		);
	}
};

export default SingleImageFootprint;
