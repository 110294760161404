import styled from 'styled-components';

export const Description = styled.div`
	font-size: 14px;
	margin: 0.5rem 0;
	color: ${p => p.theme.colors.textAccent};
	p {
		margin: 0;
		word-break: break-word;
	}
`;
