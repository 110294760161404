import { api } from './index.js';

const apiEndpoint = '/notifications';

export const getUserNotifications = async () => {
	const { data } = await api.get(`${apiEndpoint}/user`);

	return data;
};

export const getUserUnreadNotifications = async () => {
	const { data } = await api.get(`${apiEndpoint}/user/unread`);

	return data;
};

export const getNotification = async uuid => {
	const { data } = await api.get(`${apiEndpoint}/${uuid}`);

	return data;
};

export const getProjectNotifications = async project_uuid => {
	const { data } = await api.get(`${apiEndpoint}/project/${project_uuid}`);

	return data;
};

export const updateNotificationsStatus = async ({ uuids, status = 'read' }) => {
	if (!uuids?.length) {
		console.error('No uuids provided for updating notifications status');
		return;
	}

	const { data } = await api.post(
		`${apiEndpoint}/update_notifications_status?new_status=${status}`,
		uuids
	);

	return data;
};

export const deleteNotifications = async uuids => {
	const { data } = await api.delete(`${apiEndpoint}/delete_notifications`, {
		data: uuids,
	});

	return data;
};

/** All endpoints below may not be usefull */
export const updateNotification = async ({ uuid, data }) => {
	const { data: response } = await api.put(
		`${apiEndpoint}/${uuid}/status`,
		data
	);

	return response;
};

export const createGenericNotification = async data => {
	const { data: response } = await api.post(`${apiEndpoint}/generic`, data);

	return response;
};

/*  Updates the message of a notification in the database
 *  Can also update an existing message key in the message dictionary
 *  e.g.
 *  overwrite the existing message with the new message: sample_dict = {"text": "Sleijn Steikjepanne"}
 *  add a new key to the message dictionary: sample_dict = {"text2": "Sleijn Steikjepanne"}
 */
export const addNotificationMessage = async ({
	uuid,
	id = 'text',
	message,
}) => {
	const data = {
		id,
		message,
	};

	const { data: response } = await api.put(
		`${apiEndpoint}/${uuid}/message`,
		data
	);

	return response;
};
