// React imports
import { useEffect, useState, useRef } from 'react';

// Third-party library imports
import { useQuery } from '@tanstack/react-query';
import { Tooltip } from 'react-tooltip';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { BiSave } from 'react-icons/bi';
import { VscWand } from 'react-icons/vsc';
import { BsSkipEnd } from 'react-icons/bs';

// Contexts
import { useProject, ModelType } from '@contexts/Project.context';
import { useAuth } from '@contexts/User.context';
import { useToast } from '@contexts/Toast.context';

// Hooks
import useNotify from '@hooks/useNotify';
import useKeyboardShortcut from '@hooks/useKeyboardShortcut.hook';

// Utils
import { setSquareBoundary } from '@utils/map/annotation/annotation.interaction';
import {
	initAnnotationSquare,
	removeAnnotationSquare,
} from '@utils/map/square.draw';
import {
	startObjectAnnotation,
	stopDrawingObjects,
	getObjectsInSquare,
	removeAllObjectsInSquare,
	setDrawInteraction as setObjectDrawInteraction,
} from '@utils/map/object.draw';
import {
	startPolygonAnnotation,
	getPolygonsInSquare,
	stopDrawingPolygons,
	removeAllPolygonsInSquare,
	setDrawInteraction as setPolygonDrawInteraction,
} from '@utils/map/polygon.draw';
import {
	addErasorInteraction,
	removeErasorInteraction,
	startErasor,
	stopErasor,
} from '@utils/map/erasor.interaction';
import { setLayersVisibilityByCustomIds } from '@utils/map/helpers';

// Components
import {
	AnnotationToolBar,
	KeyboardShortcut,
} from '@routes/userRoutes/projects/singleProject/components/SharedStyles';
import LimitInfo from '@components/subscription/LimitInfo';
import UtilityButton from './utilityButtons/UtilityButton';
import AnnotationDrawTools from './utilityButtons/AnnotationDrawTools';

// API
import { postTileData, initTraining, getMonthlyAITrainingLimit } from '@api';

const getTileAnnotations = ({
	modelType,
	squaresInAreaOfInterest,
	activeSquareIndex,
	taskId,
}) => {
	// Ensure activeSquareIndex is within bounds
	if (
		activeSquareIndex < 0 ||
		activeSquareIndex >= squaresInAreaOfInterest.length
	) {
		throw new Error('Invalid activeSquareIndex');
	}

	const activeSquare = squaresInAreaOfInterest[activeSquareIndex];
	let data;

	switch (modelType) {
		case ModelType.OBJECT_DETECTION:
			data = getObjectsInSquare(activeSquare, taskId);
			break;
		case ModelType.SEGMENTATION:
			data = getPolygonsInSquare(activeSquare);
			break;
		default:
			throw new Error('Invalid modelType');
	}

	return data;
};

const removeAnnotationsInSquare = ({
	modelType,
	currentTileName,
	annotations,
}) => {
	switch (modelType) {
		case ModelType.OBJECT_DETECTION:
			removeAllObjectsInSquare(currentTileName);
			break;
		case ModelType.SEGMENTATION:
			removeAllPolygonsInSquare(annotations);
			break;
		default:
			throw new Error('Invalid modelType');
	}
};

export default function AnnotateOrthophoto({
	layersToShow,
	hideAnnotationSidebar,
}) {
	const {
		features,
		mapObject,
		polygonLayer,
		project,
		pickedTask,
		modelType,
		annotationMode,
		annotationDrawTool,
		toolBarVisible,
		colorOptions,
		confirmModalContent,
		dispatch,
	} = useProject();

	const { model_uuid: taskId, description: taskDescription } = pickedTask;

	const { tierPro, subscription } = useAuth();
	const {
		data: monthlyTrainingsLimit,
		isLoading: monthlyTrainingsLimitLoading,
	} = useQuery({
		queryKey: ['monthlyTrainingsLimit', subscription?.id],
		queryFn: getMonthlyAITrainingLimit,
		enabled: !!subscription?.id && tierPro,
	});

	const { gridFeatures: squaresInAreaOfInterest } = annotationMode;

	const { checkForNotifications } = useNotify();
	const { addToast } = useToast();

	const [loading, setLoading] = useState(false);

	const [activeSquareIndex, setActiveSquareIndex] = useState(0);

	const [drawing, setDrawing] = useState(true);

	const isTraining = useRef(false); // To prevent re-rendering of the annotation type

	const startAiMessage = () => {
		return (
			<>
				<p>
					Are you sure you want end the annotation session? Your data
					will be saved and AI training will start.
				</p>
				{monthlyTrainingsLimit && (
					<LimitInfo
						limit={monthlyTrainingsLimit.limit}
						used={monthlyTrainingsLimit.monthly_ai_training}
						singularName="training"
						pluralName="trainings"
						actionPastTense="used"
						actionPresentTense="Starting"
					/>
				)}
			</>
		);
	};

	const saveAndTrain = async () => {
		if (tierPro) {
			try {
				const { limit, monthly_ai_training } = monthlyTrainingsLimit;

				if (!limit || monthly_ai_training === undefined) {
					throw new Error('No limit or monthly training data');
				}

				if (monthly_ai_training >= limit) {
					addToast({
						id: `training_warning-${new Date().getTime()}`,
						className: 'bg-danger',
						autohide: false,
						title: `You have reached your subscription limit of ${limit} AI training sessions`,
						message:
							'Annotations are saved but you cannot start a new training session within this subscription period.',
					});

					saveAndExit();
					return;
				}
			} catch (error) {
				console.error(error);
				addToast({
					id: `training_warning-${new Date().getTime()}`,
					className: 'bg-danger',
					title: `Training not started`,
					message: `Annotations are saved but we are having trouble fetching your subscription training limit. Please try again later.`,
					autohide: false,
				});

				saveAndExit();

				return;
			}
		}

		dispatch({
			type: 'setConfirmModalContent',
			payload: {
				title: 'Start AI training',
				message: startAiMessage(),
				onConfirm: endAnnotation,
				onCancel: () => {},
			},
		});
	};
	const saveAndExit = async () => {
		const confirmContinue = await saveTileData();

		if (confirmContinue) {
			stopAnnotation();
		}
	};

	// End annotation session and start training
	const endAnnotation = async () => {
		const confirmContinue = await saveTileData();

		if (confirmContinue) {
			initTraining(project?.uuid, taskId)
				.then(res => {
					console.log('init training', res);
					if (res?.data?.warning) {
						addToast({
							id: `training_warning-${new Date().getTime()}`,
							className: 'bg-warning',
							title: `Training model '${taskDescription}' started with warnings:`,
							message: res.data.warning,
							autohide: false,
						});
					} else if (
						res?.data?.error ||
						res?.data?.detail ||
						res === undefined
					) {
						addToast({
							id: `training_error-${new Date().getTime()}`,
							className: 'bg-danger',
							title: `Training model '${taskDescription}' failed:`,
							message:
								(res?.data?.error || res?.data?.detail) ??
								'Unknown error starting training.',
							autohide: false,
						});
					} else if (res?.data?.status === 'success') {
						addToast({
							id: `training_success-${new Date().getTime()}`,
							className: 'bg-success',
							title: `Training model '${taskDescription}' started successfully.`,
							message:
								'The process will take some time to complete.',
							autohide: false,
						});
					}
				})
				.finally(() => {
					stopAnnotation();
					setTimeout(() => {
						checkForNotifications();
					}, 5000);
				});
		}
	};

	// Stop annotation session
	const stopAnnotation = () => {
		console.log('stopping annotation');

		if (mapObject) {
			const layers = mapObject.getLayers();
			layers?.forEach(layer => {
				const name = layer.get('name');
				if (layersToShow.includes(name)) {
					layer.setVisible(true);
				}
			});
		}

		setActiveSquareIndex(null);
		removeAnnotationSquare(mapObject);
		removeErasorInteraction(mapObject);

		mapObject.on('pointermove', () => {
			mapObject.getTargetElement().style.cursor = 'auto';
		});

		if (modelType === ModelType.OBJECT_DETECTION) {
			stopDrawingObjects(mapObject);
		} else if (modelType === ModelType.SEGMENTATION) {
			stopDrawingPolygons(mapObject);
		}

		dispatch({
			type: 'setDialogue',
			payload: null,
		});
		dispatch({
			type: 'setToolBarVisible',
			payload: true,
		});
		dispatch({
			type: 'setAnnotationMode',
			payload: null,
		});

		setLayersVisibilityByCustomIds(mapObject, layersToShow, true);
		hideAnnotationSidebar();
	};

	// Start drawing
	const startDraw = () => {
		if (drawing) return;

		setDrawing(true);
		stopErasor(mapObject);
	};

	// Start erasing
	const startErase = () => {
		if (!drawing) return;

		setDrawing(false);
		startErasor(mapObject);
	};

	// Check if there are annotations in the current square before skipping
	const checkTileBeforeSkip = () => {
		setLoading(true);

		const data = getTileAnnotations({
			modelType,
			squaresInAreaOfInterest,
			activeSquareIndex,
			taskId,
		});

		let confirmContinue = true;

		if (data?.annotations?.length > 0) {
			if (
				window.confirm(
					'You have made annotations in this square. Skipping will discard them. Still good to go?'
				)
			) {
				// Remove the annotations
				const currentTileName =
					squaresInAreaOfInterest[activeSquareIndex]?.properties
						.tile_name;
				removeAnnotationsInSquare({
					modelType,
					currentTileName,
					annotations: data.annotations,
				});
			} else {
				confirmContinue = false;
			}
		}

		setLoading(false);
		return confirmContinue;
	};

	// Save the annotations in the current square
	const saveTileData = async () => {
		setLoading(true);

		const data = getTileAnnotations({
			modelType,
			squaresInAreaOfInterest,
			activeSquareIndex,
			taskId,
		});

		if (!data?.annotations || data?.annotations?.length === 0) {
			if (
				!window.confirm(
					"No new annotations were made in this square. It could affect the model if something's missed. Still good to go?"
				)
			) {
				// Cancel saving data to annotate more
				setLoading(false);
				return false;
			}

			// Backend does not want an empty tile to be posted if there are overlapping annotations
			if (data.hasOverlappedAnnotations) {
				console.log(
					'Skipping saving empty tile with overlapping annotations'
				);
				setLoading(false);
				return true;
			}
		}

		const currentTileName =
			squaresInAreaOfInterest[activeSquareIndex]?.properties.tile_name;

		const res = await postTileData(
			project?.uuid,
			taskId,
			currentTileName,
			data.annotations
		)
			.then(() => {
				return true;
			})
			.catch(error => {
				console.error(error);
				return false;
			})
			.finally(() => {
				setLoading(false);
			});

		return res;
	};

	// change the square to annotate
	const onChangeSquare = index => {
		setActiveSquareIndex(index);
		setSquareBoundary(squaresInAreaOfInterest[index]);
		initAnnotationSquare(squaresInAreaOfInterest[index], mapObject);
	};

	// Move to the next square
	const nextSquare = async ({ skip = false }) => {
		if (loading) return;

		if (activeSquareIndex < squaresInAreaOfInterest.length - 1) {
			let confirmContinue = true;
			if (skip) {
				confirmContinue = checkTileBeforeSkip();
				console.log('skipping tile');
			} else {
				confirmContinue = await saveTileData();
				console.log('saving tile');
			}

			if (confirmContinue) {
				onChangeSquare(activeSquareIndex + 1);
			}
		} else if (activeSquareIndex === squaresInAreaOfInterest.length - 1) {
			endAnnotation();
		}
	};

	// Creates a confirm modal to cancel the annotation session
	const cancel = () => {
		dispatch({
			type: 'setConfirmModalContent',
			payload: {
				title: 'Cancel annotation session',
				message:
					'Are you sure you want to cancel the annotation mode and exit?',
				onConfirm: stopAnnotation,
				onCancel: () => {},
			},
		});
	};

	// Keyboard shortcuts
	useKeyboardShortcut(
		'Enter',
		!!annotationMode && confirmModalContent === null,
		() => nextSquare({ skip: false })
	);
	useKeyboardShortcut(
		'Escape',
		!toolBarVisible && confirmModalContent === null,
		() => cancel()
	);

	useEffect(() => {
		// change the relevant draw interaction to use the correct draw tool

		if (modelType === ModelType.SEGMENTATION) {
			setPolygonDrawInteraction({
				map: mapObject,
				freehand: annotationDrawTool === 'Freehand',
				colorOptions,
			});
		} else if (modelType === ModelType.OBJECT_DETECTION) {
			setObjectDrawInteraction({
				mapReference: mapObject,
				drawTool: annotationDrawTool,
			});
		}
	}, [annotationDrawTool]);

	// Starts the right annotation type
	useEffect(() => {
		if (activeSquareIndex === null || isTraining.current) return;

		isTraining.current = true;
		console.log('Starting annotation mode');

		if (modelType === ModelType.OBJECT_DETECTION) {
			console.log('Starting object annotation');
			startObjectAnnotation({
				colorOptionsInput: colorOptions,
				existingObjects: features,
				squareList: squaresInAreaOfInterest,
				mapReference: mapObject,
			});
		} else if (modelType === ModelType.SEGMENTATION) {
			console.log('Starting polygon annotation');
			startPolygonAnnotation({
				colorOptions,
				existingPolygonLayer: polygonLayer,
				squareList: squaresInAreaOfInterest,
				mapReference: mapObject,
			});
		}

		dispatch({
			type: 'setDialogue',
			payload: {
				header: 'Annotating',
				body: (
					<>
						<p>
							Annotate all relevant items in the square before
							clicking "Continue".
						</p>
						<p className="small text-muted">
							Also use "Continue" on tiles without target items to
							help the model learn to recognize empty areas. Only
							use "Skip" if you don't want to train the model on
							this tile.
						</p>
					</>
				),
				fullWidth: true,
			},
		});

		initAnnotationSquare(
			squaresInAreaOfInterest[activeSquareIndex],
			mapObject
		); // draws the first square

		// Zoom to the first square
		const mapView = mapObject.getView();
		mapView.setZoom(21.5);

		setSquareBoundary(squaresInAreaOfInterest[activeSquareIndex]);

		addErasorInteraction(modelType, mapObject);
	}, []);

	return (
		<>
			<AnnotationToolBar aria-label="Annotation tools">
				<Tooltip
					id="annotate-tip"
					variant="light"
					render={({ content, activeAnchor }) => (
						<span>
							{content}{' '}
							<KeyboardShortcut>
								{activeAnchor?.getAttribute(
									'data-tooltip-keyboardshortcut'
								)}
							</KeyboardShortcut>
						</span>
					)}
				/>

				<div>
					<UtilityButton
						label="Cancel"
						tooltip={{
							id: 'annotate-tip',
							content: 'Cancel the annotation mode and exit',
							place: 'top',
						}}
						onClick={cancel}
						variant="danger"
						keyboardShortcutLabel="ESC"
					/>
					<UtilityButton
						label="Save and exit"
						tooltip={{
							id: 'annotate-tip',
							content:
								'Save your annotations and exit the annotation mode',
							place: 'top',
						}}
						onClick={saveAndExit}
						variant="dark"
						icon={() => <BiSave />}
					/>
				</div>

				<AnnotationDrawTools
					startDraw={startDraw}
					startErase={startErase}
					drawing={drawing}
				/>

				<div className="d-flex gap-3 align-items-center">
					<div>
						Annotating {activeSquareIndex + 1} /{' '}
						{squaresInAreaOfInterest.length}
					</div>
					<ButtonGroup>
						<UtilityButton
							label="Start AI training"
							tooltip={{
								id: 'annotate-tip',
								content:
									'End annotation session and start AI training',
								place: 'top',
							}}
							onClick={saveAndTrain}
							disabled={
								loading ||
								(tierPro && monthlyTrainingsLimitLoading)
							}
							variant="dark"
							icon={() => <VscWand />}
						/>

						{activeSquareIndex <
							squaresInAreaOfInterest.length - 1 && (
							<>
								<UtilityButton
									label="Skip"
									tooltip={{
										id: 'annotate-tip',
										content:
											'Ignore the current tile and move to the next tile',
										place: 'top',
									}}
									disabled={loading}
									loading={loading}
									onClick={() => nextSquare({ skip: true })}
									variant="dark"
									icon={() => <BsSkipEnd />}
								/>
								<UtilityButton
									label="Continue"
									tooltip={{
										id: 'annotate-tip',
										content:
											'Save the annotations in the current tile and move to the next tile',
										place: 'top',
									}}
									disabled={loading}
									loading={loading}
									onClick={nextSquare}
									variant="success"
									keyboardShortcutLabel="↵"
								/>
							</>
						)}
					</ButtonGroup>
				</div>
			</AnnotationToolBar>
		</>
	);
}
