import { useMutation } from '@tanstack/react-query';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { IoCloseSharp } from 'react-icons/io5';

import { deleteNotifications } from '@utils/api';

const StyledDeleteButton = styled(Button)`
	position: absolute;
	top: 3px;
	right: 3px;
	z-index: 1;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 22px;
	height: 22px;
	&:disabled {
		opacity: 0.5;
		pointer-events: all;
		cursor: auto;
	}
`;

/**
 * Component to display a button for deleting a notification.
 *
 * @param {string} uuid - The UUID of the notification to delete.
 * @param {Function} refetchAll - Function to refetch all notifications after deletion.
 * @param {boolean} deleting - Flag indicating if the deletion is in progress.
 * @param {Function} setDeleting - Function to set the deleting state.
 * @returns {JSX.Element} The delete button component.
 */
const DeleteNotificationButton = ({
	uuid,
	refetchAll,
	deleting,
	setDeleting,
	disabled,
}) => {
	const { mutate: deleteMutation } = useMutation({
		mutationFn: () => {
			if (disabled || deleting) return;

			setDeleting(true);
			return deleteNotifications([uuid]);
		},
		onSuccess: () => {
			refetchAll();
		},
		onError: () => {
			setDeleting(false);
		},
	});

	return (
		<StyledDeleteButton
			title={
				disabled
					? 'Cannot remove a running notification'
					: 'Remove notification'
			}
			size="sm"
			variant="dark"
			disabled={deleting || disabled}
			onClick={deleteMutation}>
			<IoCloseSharp />
		</StyledDeleteButton>
	);
};
export default DeleteNotificationButton;
