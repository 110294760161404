export const cleanUserInput = text => {
	return text
		.replace(/[^a-zA-Z0-9 ]/g, '')
		.trim()
		.replace(/  +/g, ' ')
		.toLowerCase()
		.replace(/\b[a-z]/g, letter => letter.toUpperCase());
};

// Format number with commas as thousands separators
export const formatAreaNumber = (n, decimalPlaces = 2) => {
	if (!isNaN(parseFloat(n))) {
		// Round the number to the specified decimal places
		const roundedNumber = Number(Number(n).toFixed(decimalPlaces));

		// Use toLocaleString() to add commas as thousands separators
		return roundedNumber.toLocaleString();
	}
	return n;
};

export const recurringIntervalSwitch = interval => {
	switch (interval) {
		case 'day':
			return 'daily';
		case 'week':
			return 'weekly';
		case 'month':
			return 'monthly';
		case 'year':
			return 'yearly';
		default:
			return null;
	}
};

/**
 * Returns a new array containing unique items based on the callback function.
 *
 * @param {Array} array The array to filter.
 * @param {Function} callback The callback function used to determine uniqueness. (Default: item => item)
 * @returns {Array} A new array with unique items.
 * @example
 * const numbers = [1, 2, 3, 4, 5, 5, 4, 3, 2, 1];
 * const uniqueNumbers = uniqueBy(numbers);
 * console.log(uniqueNumbers); // Output: [1, 2, 3, 4, 5]
 *
 * const users = [
 *   { id: 1, name: 'John' },
 *   { id: 2, name: 'Jane' },
 *   { id: 3, name: 'John' },
 *   { id: 4, name: 'Jane' }
 * ];
 * const uniqueUsers = uniqueBy(users, user => user.name);
 * console.log(uniqueUsers); // Output: [{ id: 1, name: 'John' }, { id: 2, name: 'Jane' }]
 */
export const uniqueBy = (array, callback = item => item) => {
	const accumulator = new Set();
	return array.filter(item => {
		const value = callback(item);
		if (accumulator.has(value)) return false;
		accumulator.add(value);
		return true;
	});
};

/**
 * Returns an array of unique values from an array based on a callback function.
 *
 * @param {Array} array The input array.
 * @param {Function} callback The callback function used to determine uniqueness. (Default: item => item)
 * @returns {Array} An array of unique values from the input array.
 * @example
 * const users = [
 *   { id: 1, name: 'John' },
 *   { id: 2, name: 'Jane' },
 *   { id: 3, name: 'John' },
 *   { id: 4, name: 'Jane' }
 * ];
 * const uniqueNames = uniqueValuesBy(users, user => user.name);
 * console.log(uniqueNames); // Output: ['John', 'Jane']
 */
export const uniqueValuesBy = (array, callback = item => item) =>
	Array.from(new Set(array.map(item => callback(item))));
