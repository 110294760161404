import { useRef, useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Button from 'react-bootstrap/Button';
import { IoSearch, IoCloseCircleOutline } from 'react-icons/io5';

import ProductsModal from '@components/modal/ProductsModal';
import LimitInfo from '@components/subscription/LimitInfo';

import { useProject } from '@contexts/Project.context';
import { useAuth } from '@contexts/User.context';
import { useToast } from '@contexts/Toast.context';
import useNotify from '@hooks/useNotify';

import {
	setupFreehandDraw,
	removeFreehandDraw,
} from '@utils/map/freeHand.draw';
import {
	getPolygonSize,
	postDetectPolygon,
	getMonthlyDetectionsLimit,
} from '@api';

/**
 * Component to draw a selection area and run detection on the selected area
 *
 * @returns the button to draw the selection area
 */
const DetectInArea = ({ isTrained }) => {
	const {
		tierTrial,
		trialEnded,
		isEnterprise,
		activeOrgAccess,
		tierPremium,
		tierPro,
		userAccess,
		subscription,
		subscriptionActive,
	} = useAuth();

	const {
		project,
		pickedTask,
		toolBarVisible,
		mapObject,
		isDemo,
		hexagonLayer,
		dispatch,
	} = useProject();

	const taskId = pickedTask?.model_uuid;

	const { addToast } = useToast();
	const { checkForNotifications } = useNotify();

	const drawRef = useRef(null);

	const [showProductsModal, setShowProductsModal] = useState(false);

	const {
		data: monthlyDetectionsLimit,
		isLoading: monthlyDetectionsLimitLoading,
		refetch: refetchMonthlyDetectionsLimit,
	} = useQuery({
		queryKey: ['monthlyDetectionsLimit', subscription?.id],
		queryFn: getMonthlyDetectionsLimit,
		enabled: !!subscription?.id && tierPro,
	});

	const allowedToDetect = async () => {
		const showAlert = message => {
			alert(message);
			return false;
		};

		if (tierTrial && trialEnded) {
			return showAlert(
				'Your trial has ended and you can no longer detect objects'
			);
		}

		if (tierPro) {
			if (!subscriptionActive) {
				return showAlert(
					'Your user needs an active subscription to detect objects'
				);
			}

			try {
				const res = await refetchMonthlyDetectionsLimit();
				const { limit, monthly_detections } = res?.data;

				if (!limit || monthly_detections === undefined) {
					return showAlert(
						'We are having trouble fetching your subscription detections limit. Please try again later.'
					);
				}

				if (monthly_detections >= limit) {
					setShowProductsModal(true);
					return false;
				}
			} catch (error) {
				console.error(error);
				return showAlert(
					'Something went wrong while checking your subscription detections limit. Try again later.'
				);
			}
		}

		if (tierPremium && !userAccess?.includes('detect')) {
			return showAlert(
				'Your user does not have access rights to detect objects'
			);
		}

		if (isEnterprise) {
			const hasOrgAccess =
				activeOrgAccess.includes('all') ||
				activeOrgAccess.includes('detect');
			if (!hasOrgAccess) {
				return showAlert(
					'Your user does not have access rights to detect objects'
				);
			}
		}

		return true; // Allow detection if none of the conditions are met
	};

	const [isDrawing, setIsDrawing] = useState(false);
	const initDetectionFlow = async () => {
		const allowed = await allowedToDetect();
		if (!allowed) {
			resetDetectionFlow();
			return;
		}

		dispatch({
			type: 'setDialogue',
			payload: {
				header: 'Set a boundary',
				body: (
					<p>
						Please draw a boundary around the region where you wish
						to generate detections. Press ESC to cancel.
					</p>
				),
			},
		});

		dispatch({ type: 'setToolBarVisible', payload: false });

		setIsDrawing(true);
		hexagonLayer?.setVisible(false);
	};

	useEffect(() => {
		if (!isDrawing) return;
		drawSelection(); // Start drawing selection

		const handleKeyUp = e =>
			e.key === 'Escape' && !toolBarVisible && resetDetectionFlow();

		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, [isDrawing]);

	const drawSelection = () => {
		// Setup draw interaction
		const { draw, layer } = setupFreehandDraw({
			type: 'Polygon',
			mapRef: mapObject,
			freehand: false,
		});
		drawRef.current = { draw, layer };

		draw.on('drawend', async e => {
			setIsDrawing(false);
			hexagonLayer?.setVisible(true);
			const coordinates = e.feature.getGeometry().getCoordinates();

			const polygonSize = await getPolygonSize(
				coordinates,
				project?.uuid,
				taskId
			);

			if (tierTrial && polygonSize.number_of_tiles > 50) {
				window.alert(
					'Trial users can not run detection on large areas. Please select a smaller area.'
				);
				removeFreehandDraw({
					mapRef: mapObject,
					draw,
					layer,
				});
				initDetectionFlow();
			} else {
				dispatch({
					type: 'setConfirmModalContent',
					payload: {
						title: 'Confirm Selection',
						message: (
							<>
								<p>
									Detecting objects is a resource-intensive
									process that may take several minutes,
									depending on the area selected.
								</p>
								<p>
									Do you want to run detection in the selected
									area?
								</p>

								{monthlyDetectionsLimit && (
									<>
										<LimitInfo
											limit={monthlyDetectionsLimit.limit}
											used={
												monthlyDetectionsLimit.monthly_detections
											}
											singularName="detection"
											pluralName="detections"
											actionPastTense="used"
											actionPresentTense="Running"
										/>
									</>
								)}
							</>
						),
						onConfirm: () => runPostDetection(coordinates),
						onCancel: () => resetDetectionFlow(),
					},
				});
			}
		});
	};

	const resetDetectionFlow = () => {
		dispatch({ type: 'setDialogue', payload: null });
		dispatch({ type: 'setToolBarVisible', payload: true });
		setIsDrawing(false);
		hexagonLayer?.setVisible(true);

		const { draw, layer } = drawRef?.current || {
			draw: null,
			layer: null,
		};
		if (draw && layer) {
			removeFreehandDraw({
				mapRef: mapObject,
				draw,
				layer,
			});
		}
	};

	const runPostDetection = coordinates => {
		postDetectPolygon(project?.uuid, taskId, coordinates)
			.then(res => {
				const resMessage =
					'Detection is running. Depending on the area selected, this may take several minutes. You will be notified when detection is complete.';

				if (res?.warning) {
					addToast({
						id: `detection_warning-${new Date().getTime()}`,
						className: 'bg-warning',
						title: `Detection started with warnings:`,
						message: (
							<>
								<p>{res.warning}.</p>
								<p className="mb-0">{resMessage}</p>
							</>
						),
						autohide: false,
					});
				} else {
					addToast({
						id: `detection_success-${new Date().getTime()}`,
						className: 'bg-success',
						title: `Running detection...`,
						message: resMessage,
						autohide: false,
					});
				}

				checkForNotifications();
			})
			.catch(error => {
				addToast({
					id: `detection_error-${new Date().getTime()}`,
					className: 'bg-danger',
					title: `Error Running detection...`,
					message:
						error?.response?.data?.detail ||
						'Unknown error running detection.',
					autohide: false,
				});
			})
			.finally(() => {
				resetDetectionFlow();
			});
	};

	if (!taskId) return null;

	return (
		<>
			{isDrawing ? (
				<Button
					disabled={toolBarVisible || isDemo}
					onClick={resetDetectionFlow}
					variant="dark-secondary"
					className="btn-dark secondary"
					data-tooltip-id="tooltip-toolbar-root"
					data-tooltip-content="Cancel detection mode"
					data-tooltip-place="top">
					<IoCloseCircleOutline className="m-1 mb-2" />
					Cancel Detection
				</Button>
			) : (
				<Button
					disabled={
						!isTrained ||
						!toolBarVisible ||
						isDemo ||
						(tierPro && monthlyDetectionsLimitLoading)
					}
					onClick={initDetectionFlow}
					data-tooltip-id="tooltip-toolbar-root"
					data-tooltip-content={
						tierTrial && trialEnded
							? 'Your trial has ended'
							: 'Detect objects in a selected area'
					}
					data-tooltip-place="top"
					className="btn-dark secondary">
					<IoSearch className="m-1 mb-2" />
					Detect Objects
				</Button>
			)}

			{showProductsModal && (
				<ProductsModal
					title={`You have reached your limit of ${monthlyDetectionsLimit.limit} detections`}
					message={`Please upgrade your subscription to continue detecting objects.`}
					filterProductsBy={{
						monthly_detect_limit: monthlyDetectionsLimit.limit,
					}}
					onClose={() => setShowProductsModal(false)}
				/>
			)}
		</>
	);
};

export default DetectInArea;
