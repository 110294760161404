import { useEffect } from 'react';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';

import { useProject } from '@contexts/Project.context';
import { getLayerByCustomId, deleteLayerByCustomId } from '@utils/map/helpers';
import Checkbox from '../sidebars/sidebarElements/Checkbox';

/**
 * Removes overlays with a specific element class name from a map object.
 * 
 * @param {Object} options The options for removing overlays.
 * @param {Object} options.mapObject The map object from which to remove overlays.
 * @param {string} options.className The class name of the element of the overlay to be removed.
 * @returns {void}
 */
const removeOverlaysWithElementClassName = ({ mapObject, className }) => {
	mapObject
		.getOverlays()
		.getArray()
		.filter(overlay =>
			overlay?.getElement()?.className?.includes(className)
		)
		.forEach(overlay => mapObject.removeOverlay(overlay));
};

const layerId = 'measurementLayer';
/**
 * MeasurementLayer component.
 * 
 * @returns {JSX.Element} The MeasurementLayer component.
 */
const MeasurementLayer = () => {
	const { mapObject, measurementLayer, dispatch } = useProject();

	useEffect(() => {
		if (!mapObject) return;

		let layer = getLayerByCustomId(mapObject, layerId);

		if (!layer) {
			console.log('Creating new measurement layer');
			layer = new VectorLayer({
				zIndex: 30,
				source: new VectorSource(),
				name: 'Measurements',
				properties: {
					customLayerId: layerId,
				},

				// Set style based on feature type
				style: feature => feature.get('style'),
			});
			mapObject.addLayer(layer);
		} else {
			console.log('Measurement layer already exists. Clearing source');
			layer.getSource().clear();
		}

		dispatch({ type: 'setMeasurementLayer', payload: layer });

		// Cleanup
		return () => {
			removeOverlaysWithElementClassName({
				mapObject: mapObject,
				className: `${layerId}Overlay`,
			});

			deleteLayerByCustomId(mapObject, layerId);
			dispatch({ type: 'setMeasurementLayer', payload: null });
		};
	}, [mapObject]);

	if (!measurementLayer) return null;
	return (
		<div id={layerId}>
			<Checkbox
				label={measurementLayer.get('name')}
				layer={measurementLayer}
				defaultState={measurementLayer?.getVisible()}
				handleCheck={() => {
					measurementLayer?.setVisible(true);
				}}
				handleUncheck={() => {
					measurementLayer?.setVisible(false);
				}}
				canEdit={false}
			/>
		</div>
	);
};

export default MeasurementLayer;
