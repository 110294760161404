import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';

import { initiateTransaction, getUserSubscription } from '@api';
import { useAuth } from '@contexts/User.context';
import View from '@components/layout/views/View';
import Loader from '@components/loading/Loader';
import Alert from '@components/alert/Alert';

export default function Checkout() {
	const { currentUser } = useAuth();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [loading, setLoading] = useState(true);
	const [message, setMessage] = useState('');
	const [error, setError] = useState(false);

	const referenceId = searchParams.get('reference_id');
	const priceId = searchParams.get('price_id');
	const mode = searchParams.get('mode');
	const returnPath = searchParams.get('return_path');

	const paymentSuccess = searchParams.get('success');
	const paymentCanceled = searchParams.get('canceled');

	// Mutation to initiate a transaction
	const initiateTransactionMutation = useMutation({
		mutationFn: () =>
			initiateTransaction({
				price_id: priceId,
				mode: mode || undefined,
				return_path: returnPath || '',
			}),
		onSuccess: data => {
			window.location = data.checkout_url;
		},
		onError: err => {
			console.error(err);
			setMessage(
				'Something went wrong and we could not start the checkout process. Please try again later.'
			);
			setError(true);
			setLoading(false);
		},
	});

	// Gets the status of the a transaction if referenceId is set
	const { data: userSubscription, error: userSubscriptionError } = useQuery({
		queryKey: ['userSubscription', currentUser?.subscription_id],
		queryFn: () => getUserSubscription(currentUser.subscription_id),
		retry: 5, // Will retry 10 times before failing
		retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000), //set to double (starting at 1000ms) with each attempt, but not exceed 30 seconds:
		enabled: mode === 'subscription' && !!paymentSuccess,
	});

	// Checking for an active subscription after a successful subscription purchase
	useEffect(() => {
		if (!paymentSuccess || mode !== 'subscription') return;

		console.log('handling sub');

		if (userSubscription && userSubscription.status === 'active') {
			setMessage(
				<>
					Subscription created.
					<br /> You will be redirected shortly...
				</>
			);
			setError(false);
			setTimeout(() => {
				console.log('sub redirect');
				navigate(returnPath ?? '/profile');
			}, 5000);
		} else if (
			userSubscriptionError ||
			(userSubscription && userSubscription.status !== 'active')
		) {
			console.error(userSubscriptionError);

			setLoading(false);
			setMessage(
				<>
					<p>An error occurred processing your subscription.</p>
					<p className="mb-0">
						Please contact ut at{' '}
						<a href="mailto:post@biodrone.no">post@biodrone.no</a>{' '}
						and provide reference code:
					</p>{' '}
					<pre className="mb-0">{referenceId}</pre>
				</>
			);
			setError(true);
		}
	}, [userSubscription, userSubscriptionError]);

	// On page load check if one of three scenarios:
	// 1. Payment was successful
	// 2. Payment was canceled
	// 3. Initiate a transaction
	useEffect(() => {
		if (paymentSuccess) return;

		if (paymentCanceled) {
			setMessage(
				<>
					Order canceled!
					<br /> You will be redirected shortly...
				</>
			);
			setError(true);
			setLoading(false);
			setTimeout(() => {
				console.log('canceled redirect');
				navigate(returnPath ?? '/');
			}, 5000);
		} else {
			if (priceId) {
				initiateTransactionMutation.mutate();
			} else {
				setLoading(false);
				setMessage(
					'No product selected and checkout could not be initiated.'
				);
				setError(true);
			}
		}
	}, []);

	return (
		<View title="Checkout" maxWidth="800">
			{loading && (
				<>
					<h2>Processing...</h2>
					<Loader />
				</>
			)}

			{message && (
				<Alert variant={error ? 'danger' : 'success'}>{message}</Alert>
			)}
		</View>
	);
}
