import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';

import AdminView from '@components/layout/views/AdminView';
import Loader from '@components/loading/Loader';
import Table from './components/UsersTable';
import UsersFilter from './components/UsersFilter';
import { FooterText } from '@routes/adminRoutes/sharedComponents/SharedStyledComponents';

import { adminGetUsers } from '@api';

/*
 * Shows a admin table of users with various filters
 */
const Users = () => {
	const [searchParams] = useSearchParams();

	/**
	 * Fetch users from the API to populate the table
	 */
	const fetchUsers = async ({ pageParam = 0 }) => {
		const search = searchParams.get('search');
		const tier = searchParams.get('tier');
		const role = searchParams.get('role');
		const sort_by = searchParams.get('sort_by');
		const sort_order = searchParams.get('sort_order');

		return await adminGetUsers({
			offset: pageParam ?? 0,
			limit: 100,
			search,
			tier,
			role,
			sort_by,
			sort_order,
			detailed: true,
		});
	};

	const {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		refetch,
	} = useInfiniteQuery({
		queryKey: ['admin_users'],
		queryFn: fetchUsers,
		getNextPageParam: (lastPage, allPages) => {
			if (Math.ceil(lastPage.total / lastPage.limit) > allPages.length) {
				return lastPage.offset + lastPage.users?.length;
			}
			return undefined;
		},
		refetchOnWindowFocus: false,
	});

	const [users, total] = useMemo(() => {
		const users = data?.pages?.flatMap(page => page.users) ?? [];
		const total = data?.pages?.[data.pages.length - 1]?.total ?? 0;
		return [users, total];
	}, [data]);

	useEffect(() => {
		if (isFetching) return;
		refetch(); // Call refetch() when searchParams change
	}, [searchParams]);

	return (
		<AdminView title="Users">
			<UsersFilter />

			<div className="position-relative">
				{isFetching && <Loader />}

				{!isFetching && error && (
					<div className="alert alert-danger" role="alert">
						Something went wrong:{' '}
						<p className="mb-0">
							- {error.message ?? 'Unknown error'}
						</p>
						{error?.response?.data?.detail && (
							<p className="mb-0">
								- {error.response.data.detail}
							</p>
						)}
					</div>
				)}

				{!isFetching && users?.length === 0 && (
					<div>No users found</div>
				)}

				{!isFetching && users?.length > 0 && (
					<>
						<Table data={users} loading={isFetching} />

						<footer className="col-md-12 mb-5">
							<FooterText>
								Showing <span>{users.length}</span> of{' '}
								<span>{total}</span>
							</FooterText>

							{users?.length < total && (
								<div className="text-center">
									<Button
										variant="success"
										className="mt-2 mb-2 col-6"
										disabled={
											!hasNextPage || isFetchingNextPage
										}
										onClick={() => fetchNextPage()}>
										<span>Load more</span>{' '}
										{isFetchingNextPage && (
											<Spinner
												animation="border"
												variant="light"
												size="sm"
											/>
										)}
									</Button>
								</div>
							)}
						</footer>
					</>
				)}
			</div>
		</AdminView>
	);
};

export default Users;
