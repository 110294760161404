import { useQueryClient } from '@tanstack/react-query';

export default function useNotify() {
	// Get QueryClient from the context
	const queryClient = useQueryClient();

	const checkForNotifications = () => {
		// Invalidate the query after 10s, 20s, and 40s to ensure that the backend has had time to save the data
		// The notifications component also invalidates its self after 5 minutes
		const timeOutDelays = [10000, 20000, 40000]; // 10s, 20s, 40s
		timeOutDelays.forEach(delay => {
			setTimeout(() => {
				queryClient.invalidateQueries({ queryKey: ['notifications'] });
			}, delay);
		});
	};

	return { checkForNotifications };
}
