import { getArea, getLength } from 'ol/sphere.js';
import Overlay from 'ol/Overlay.js';

/**
 * Formats the area of a geometry.
 *
 * @param {Object} geometry The geometry object.
 * @returns {String} The formatted area in m² or kilometers².
 * @example
 * // returns 'Area: 1.23 km²'
 */
const formatArea = geometry => {
	const area = getArea(geometry);
	return area > 10000
		? `Area: ${Math.round((area / 1000000) * 100) / 100} km²`
		: `Area: ${Math.round(area * 100) / 100} m²`;
};

/**
 * Formats the length of a geometry.
 *
 * @param {Geometry} geometry The geometry to calculate the length for.
 * @returns {String} The formatted length in meters or kilometers.
 * @example
 * // returns 'Length: 1.23 km'
 */
const formatLength = geometry => {
	const length = getLength(geometry);
	return length > 100
		? `Length: ${Math.round((length / 1000) * 100) / 100} km`
		: `Length: ${Math.round(length * 100) / 100} m`;
};

/**
 * Creates a tooltip element with the specified class name and style.
 *
 * @param {Object} options - The options for creating the tooltip element.
 * @param {string} options.className - The class name for the tooltip element.
 * @param {string} options.style - The style for the tooltip element.
 * @returns {HTMLElement} The created tooltip element.
 */
const createTooltipElement = ({ className, style }) => {
	const element = document.createElement('div');
	element.className = className;
	element.style = style;
	return element;
};

/**
 * Creates an overlay for a map object.
 * @param {Object} options The options for creating the overlay.
 * @param {MapObject} options.mapObject The map object to add the overlay to.
 * @param {String} options.type The type of the feature to overlay.
 * @param {String} options.className The class name for the overlay element.
 * @returns {Overlay} The created tooltip overlay.
 */
export const createOverlay = ({ mapObject, type, className }) => {
	const tooltipOverlay = new Overlay({
		element: createTooltipElement({
			className: className,
			style: `font-size: 12px; margin-left: 3px;`, // 3px to fix tooltip alignment.
		}),
		positioning: 'bottom-center',
		offset: type === 'LineString' ? [0, -7.5] : [0, 0],
	});

	tooltipOverlay.getElement().parentElement.style.pointerEvents = 'none';
	mapObject.addOverlay(tooltipOverlay);

	return tooltipOverlay;
};

/**
 * Removes an overlay from the map.
 *
 * @param {Object} options - The options for removing the overlay.
 * @param {Object} options.mapObject - The map object.
 * @param {Object} options.overlay - The overlay to be removed.
 */
export const removeOverlay = ({ mapObject, overlay }) => {
	overlay.getElement().remove();
	mapObject.removeOverlay(overlay);
};

/**
 * Retrieves overlay information based on the type and geometry.
 * @param {Object} options The options object.
 * @param {String} options.type The type of the feature to overlay.
 * @param {Object} options.geometry The geometry of the feature to overlay.
 * @returns {Array} [String, Coordinate].
 */
const getOverlayInfo = ({ type, geometry }) => {
	if (type === 'Polygon')
		return [
			formatArea(geometry),
			geometry.getInteriorPoint().getCoordinates(),
		];

	if (type === 'LineString')
		return [formatLength(geometry), geometry.getLastCoordinate()];
};

/**
 * Sets the overlay information.
 *
 * @param {Object} options The options for setting the overlay information.
 * @param {Overlay} options.overlay The overlay.
 * @param {string} options.type The type of the feature to overlay.
 * @param {Object} options.geometry The geometry of the feature to overlay.
 * @returns {void}
 */
export const setOverlayInfo = ({ overlay, type, geometry }) => {
	const [text, coordinate] = getOverlayInfo({
		type: type,
		geometry: geometry,
	});

	overlay.getElement().innerText = text;
	overlay.setPosition(coordinate);
};

/**
 * Listens for a specific keyboard key press event and executes a callback function once.
 *
 * @param {Object} options The options for the keyboard listener.
 * @param {string} options.key The key to listen for.
 * @param {Function} options.callback The callback function to execute when the key is pressed.
 */
export const keyboardListenOnce = ({ key, callback }) => {
	document.addEventListener(
		'keydown',
		e => {
			if (e.key.toLowerCase() === key.toLowerCase()) callback();
		},
		{ once: true }
	);
};
