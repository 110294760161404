import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

import Loader from '@components/loading/Loader';
import View from '@components/layout/views/View';
import Input from '@components/form/Input';
import Alert from '@components/alert/Alert';

import { resetPassword } from '@api';
import { checkPassword } from '../utils/helpers';

const PasswordReset = () => {
	const [errorMessages, setErrorMessages] = useState([]);

	const location = useLocation();
	const navigate = useNavigate();

	const searchParams = new URLSearchParams(location?.search);

	const { control, handleSubmit } = useForm({
		defaultValues: {
			email: searchParams?.get('email'),
			token: searchParams?.get('token'),
			password: '',
			passwordConfirm: '',
		},
	});

	const resetMutation = useMutation({
		mutationFn: values => resetPassword(values),
		onError: err => {
			console.log('err', err);
			setErrorMessages([err?.response?.data?.detail ?? 'Unknown error']);
		},
		onSuccess: () => {
			setTimeout(() => {
				navigate('/login');
			}, 5000);
		},
	});

	const onSubmit = async data => {
		setErrorMessages([]);

		const { email, token, password, passwordConfirm } = data;

		const passwordCheck = checkPassword(password, passwordConfirm);
		if (!passwordCheck.validated) {
			setErrorMessages(e => [...e, ...passwordCheck.errors]);
			return;
		}

		resetMutation.mutate({
			email,
			token,
			password,
			passwordConfirm,
		});
	};

	const renderErrorMessages = () => {
		if (errorMessages.length > 0) {
			return (
				<Alert variant="danger">
					{errorMessages.map((message, index) => (
						<p key={`error-${index}`}>{message}</p>
					))}
				</Alert>
			);
		}
	};

	return (
		<View maxWidth={550} title="Reset your password">
			{renderErrorMessages()}

			{resetMutation.isLoading && <Loader />}
			<Card bg="dark" border="dark" body className="p-2">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<FormContent
						resetMutation={resetMutation}
						control={control}
					/>
				</Form>
			</Card>

			<p className="mt-5">
				Remember your old password? <Link to="/login">Log in</Link>
			</p>
		</View>
	);
};
export default PasswordReset;

const FormContent = ({ resetMutation, control }) => {
	if (resetMutation?.isSuccess) {
		return (
			<Alert variant="success">
				<p className="mb-0">
					Password reset succeeded.
					<br /> <Link to={'/login'}>Click here to log in</Link> or
					wait to be redirected.
				</p>
			</Alert>
		);
	}

	return (
		<>
			<Controller
				name="email"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						type="email"
						label="Email address"
						required
						disabled
					/>
				)}
			/>

			<Controller
				name="password"
				autocomplete="new-password"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						type="password"
						label="New Password"
						required
					/>
				)}
			/>

			<Controller
				name="passwordConfirm"
				autocomplete="new-password"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						type="password"
						label="Confirm New Password"
						required
					/>
				)}
			/>

			<Button
				variant="success"
				type="submit"
				disabled={resetMutation.isLoading}>
				Reset Password
			</Button>
		</>
	);
};
