import styled from 'styled-components';

const StyledDate = styled.p`
	font-size: 12px;
	color: ${p => p.theme.colors.textAccent};
	margin: 0 0 0.25rem;
`;

/**
 * Component to display the notification date.
 *
 * @param {string} date - The date to display.
 * @returns {JSX.Element|null} The formatted date or null if no date is provided.
 */
const NotificationDate = ({ date }) => {
	if (!date) return null;

	return (
		<StyledDate>
			{new Intl.DateTimeFormat('nb-NO', {
				hour: 'numeric',
				minute: 'numeric',
				day: 'numeric',
				month: 'short',
			}).format(new Date(date))}
		</StyledDate>
	);
};
export default NotificationDate;
