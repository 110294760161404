import { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useProject } from '@contexts/Project.context';
import { getMasterFeatures } from '@utils/api';
import {
	makePolygonLayer,
	updatePolygonStyles,
} from '@utils/map/polygon.layer';
import Checkbox from '../sidebars/sidebarElements/Checkbox';
import NoResult from '../sidebars/sidebarElements/NoAnalyzesResult';
import { setupLayerDetails } from '@utils/map/helpers';
import ObjectInfoLayer from './ObjectInfoLayer';

const PolygonLayer = ({ setExportData, setActiveSidebar, children }) => {
	const {
		mapObject,
		project,
		features,
		polygonLayer,
		colorScheme,
		colorOptions,
		pickedTask,
		dispatch,
	} = useProject();

	const [showInfoLayer, setShowInfoLayer] = useState(true);

	const taskId = pickedTask?.model_uuid;

	const {
		data: polygonData,
		isError,
		error,
	} = useQuery({
		queryKey: [taskId, 'ploygon_master_features', project.uuid],
		queryFn: () => getMasterFeatures(project.uuid, taskId, 'polygon'),
		enabled: !!project?.uuid && !!taskId,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const updatePolygonLayer = useCallback(() => {
		if (features?.length > 0 && mapObject) {
			const { modelClasses, colorStyles } = setupLayerDetails(
				features,
				colorOptions
			);

			dispatch({ type: 'setColorScheme', payload: colorStyles });
			dispatch({ type: 'setPickedModelClasses', payload: modelClasses });

			console.log('adding polygon layer');
			const layer = makePolygonLayer(mapObject, features, colorStyles);
			dispatch({ type: 'setPolygonLayer', payload: layer });
		} else {
			if (mapObject && polygonLayer) {
				dispatch({ type: 'setPickedModelClasses', payload: null });

				mapObject.removeLayer(polygonLayer);
				dispatch({ type: 'setPolygonLayer', payload: null });
			}
		}
	}, [features]);

	useEffect(() => {
		if (polygonData?.features) {
			dispatch({ type: 'setFeatures', payload: polygonData.features });
			setExportData(polygonData);
		}

		if (isError) {
			console.warn('could not fetch polygon', error);
			dispatch({ type: 'setFeatures', payload: [] });
			if (mapObject && polygonLayer) {
				mapObject.removeLayer(polygonLayer);
				dispatch({ type: 'setPolygonLayer', payload: null });
			}
		}
	}, [polygonData, isError]);

	useEffect(() => {
		updatePolygonLayer();
	}, [updatePolygonLayer]);

	useEffect(() => {
		updatePolygonStyles(colorScheme);
	}, [colorScheme]);

	if (!polygonLayer) return <NoResult />;

	return (
		<>
			<div id="polygonLayer">
				<Checkbox
					label={polygonLayer.get('name')}
					defaultState={polygonLayer?.getVisible()}
					handleClick={setActiveSidebar}
					handleCheck={() => {
						polygonLayer.setVisible(true);
						setShowInfoLayer(true);
					}}
					handleUncheck={() => {
						polygonLayer.setVisible(false);
						setShowInfoLayer(false);
					}}
					layer={polygonLayer}
				/>
			</div>
			{showInfoLayer && (
				<ul>
					<li>
						<ObjectInfoLayer />
					</li>
				</ul>
			)}
		</>
	);
};

export default PolygonLayer;
