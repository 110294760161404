import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { getBillingPortalUrl } from '@api';

import { useAuth } from '@contexts/User.context';

import Loader from '@components/loading/Loader';
import {
	StripeProductsButtonGroup,
	StripeProductButton,
} from '@components/products/StripeProducts';
import ProductsFooter from '@components/products/ProductsFooter';

const SubscriptionsAvailableForUser = ({ filterProductsBy }) => {
	const { currentUser } = useAuth();

	// Create Stripe billing portal URL
	const {
		data: stripeBillingPortalUrl,
		isLoading: stripeBillingPortalUrlLoading,
	} = useQuery({
		queryKey: ['billingPortalUrl', currentUser?.uuid],
		queryFn: () =>
			getBillingPortalUrl({ show_default_subscriptions: true }),
		enabled: !!currentUser?.uuid,
	});

	const availableSubscriptions =
		stripeBillingPortalUrl?.available_subscriptions ?? null;

	const filteredSubscriptions = useMemo(() => {
		// If no filters are provided, return all available subscriptions
		if (!filterProductsBy) return availableSubscriptions;

		// Filter the available subscriptions based on the filter criteria
		return availableSubscriptions?.filter(product => {
			// Check if every key in filterProductsBy meets the criteria
			return Object.keys(filterProductsBy).every(key => {
				// Get the product limit for the current key
				const productLimit = product.product_metadata?.[key];
				// Include the product if the limit is undefined or meets the filter criteria

				return (
					productLimit === undefined ||
					productLimit > filterProductsBy[key]
				);
			});
		});
		// Recalculate the filtered subscriptions only when availableSubscriptions or filterProductsBy changes
	}, [availableSubscriptions, filterProductsBy]);

	if (stripeBillingPortalUrlLoading) return <Loader />;

	return (
		<>
			{filteredSubscriptions && (
				<>
					{filteredSubscriptions.length > 0 ? (
						<>
							<StripeProductsButtonGroup>
								{filteredSubscriptions.map(p => {
									return (
										<StripeProductButton
											key={p.id}
											p={p}
											overwriteUrl={p.billing_portal_url}
										/>
									);
								})}
							</StripeProductsButtonGroup>
							<ProductsFooter />
						</>
					) : (
						<p className="mt-3 alert alert-warning">
							No suitable subscriptions available. Contact{' '}
							<a
								href="mailto:post@biodrone.no"
								className="inherit">
								post@biodrone.no
							</a>{' '}
							for assistance.
						</p>
					)}
				</>
			)}
		</>
	);
};
export default SubscriptionsAvailableForUser;
