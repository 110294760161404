import { Polygon } from 'ol/geom';
import { Feature } from 'ol/index';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Stroke, Style } from 'ol/style';
import Fill from 'ol/style/Fill';

let hexagonLayer = null;

/**
 * @param {Object} currentMap - Current map object
 * @param {Array} features - Array of features
 * @param {Object} hexagonColor - Object with color and opacity
 * @returns {void}
 *
 */
export const makeHexagonLayer = (currentMap, features, hexagonColor) => {
	if (hexagonLayer) {
		currentMap.removeLayer(hexagonLayer);
		hexagonLayer = null;
	}
	let hexagonFeatures = [];

	features.forEach(feature => {
		let hexagon = makeHexagon(feature, hexagonColor);
		hexagonFeatures.push(hexagon);
	});

	let hexagonSource = new VectorSource({
		features: hexagonFeatures,
	});

	hexagonLayer = new VectorLayer({
		name: 'Hexagons',
		renderBuffer: 2000,
		source: hexagonSource,
		opacity: 0.8,
		zIndex: 20,
		properties: {
			customLayerId: 'hexagonLayer',
		},
	});
	currentMap.addLayer(hexagonLayer);
	return hexagonLayer;
};

export const hexagonStyle = (rgbColor = '0, 0, 128', opacity = '0.2') =>
	new Style({
		stroke: new Stroke({
			color: `rgba(${rgbColor}, ${opacity})`,
			width: 4,
		}),
		fill: new Fill({
			color: `rgba(${rgbColor}, 0.2)`,
		}),
	});

export const hiddenHexagonStyle = new Style({
	stroke: new Stroke({
		color: 'rgba(0, 0, 128, 0)',
		width: 4,
	}),
	fill: new Fill({
		color: 'rgba(0, 0, 128, 0)',
	}),
});

export const getHexagonOpacity = heatIndicator => {
	const min = 0.2; // Minimum value of the range
	const max = 1; // Maximum value of the range

	// Map the heatIndicator value to the range of min to max
	const mappedValue = min + heatIndicator * (max - min);

	return mappedValue.toFixed(2);
};

const makeHexagon = (feature, hexagonColor) => {
	let coordinates = feature.metadata.hexagon_polygon;
	let hexagon = new Feature({
		featureType: 'hexagon',
		data: { ...feature },
		geometry: new Polygon([
			[
				[coordinates[0][0], coordinates[0][1]],
				[coordinates[1][0], coordinates[1][1]],
				[coordinates[2][0], coordinates[2][1]],
				[coordinates[3][0], coordinates[3][1]],
				[coordinates[4][0], coordinates[4][1]],
				[coordinates[5][0], coordinates[5][1]],
				[coordinates[0][0], coordinates[0][1]],
			],
		]),
	});

	const hexagonOpacity = getHexagonOpacity(feature.metadata.heatIndicator);

	const style = hexagonStyle(hexagonColor.color, hexagonOpacity);

	hexagon.setStyle(style);
	return hexagon;
};
