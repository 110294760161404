import styled from 'styled-components';

import PopoverPicker from '@components/popoverPicker/PopoverPicker';
import useDebounce from '@hooks/useDebounce';

const ColorMenu = styled.div`
	margin-top: 20px;
`;

const ModifyHexagon = ({ hexagonColor, setHexagonColor }) => {
	const [r, g, b] = hexagonColor.color.split(', ').map(Number);
	const defaultColor = { r, g, b, a: 1 };

	const debouncedChange = useDebounce(rgb => {
		const color = `${rgb.r}, ${rgb.g}, ${rgb.b}`;
		setHexagonColor({
			color,
		});
	}, 300); // 300ms debounce time

	const changeColor = e => {
		debouncedChange(e);
	};

	return (
		<ColorMenu>
			<PopoverPicker
				label="Hexagons color"
				color={defaultColor}
				onChange={val => {
					changeColor(val);
				}}
				popDirection="left"
				stackItems={false}
				hideAlpha
			/>
		</ColorMenu>
	);
};

export default ModifyHexagon;
