import useSetSearchParams from '@hooks/useSetSearchParams.hook';
import SearchField from '@components/filters/SearchField';
import SelectField from '@components/filters/selectField';
import {
	FilterWrapper,
	GroupedFilter,
} from '@routes/adminRoutes/sharedComponents/SharedStyledComponents';

import { ROLES, TIERS } from '@contexts/User.context';

const UsersFilter = () => {
	const customSetSearchParams = useSetSearchParams();

	return (
		<FilterWrapper>
			<SearchField
				setSearch={val => {
					customSetSearchParams({ search: val });
				}}
				label="Search by email or uuid"
				searchParamsId="search"
				noWrap
			/>

			<SelectField
				label="Filter by tier"
				setValue={val => {
					customSetSearchParams({ tier: val });
				}}
				searchParamsId="tier"
				options={Object.keys(TIERS).map(tier => {
					return { label: tier, value: TIERS[tier] };
				})}
				id="filter-tier"
			/>

			<SelectField
				label="Filter by role"
				setValue={val => {
					customSetSearchParams({ role: val });
				}}
				searchParamsId="role"
				options={Object.keys(ROLES).map(role => {
					return { label: role, value: ROLES[role] };
				})}
				id="filter-role"
			/>

			<GroupedFilter>
				<SelectField
					label="Sort by"
					setValue={val => {
						customSetSearchParams({ sort_by: val });
					}}
					searchParamsId="sort_by"
					options={[
						{
							label: 'Created',
							value: 'created_at',
						},
						{
							label: 'Email',
							value: 'email',
						},
					]}
					defaultValue="created_at"
					id="filter-sort"
					allOption={false}
				/>

				<SelectField
					label="Order"
					setValue={val => {
						customSetSearchParams({ sort_order: val });
					}}
					searchParamsId="sort_order"
					options={[
						{
							label: 'Descending',
							value: 'desc',
						},
						{
							label: 'Ascending',
							value: 'asc',
						},
					]}
					defaultValue="desc"
					id="filter-order"
					allOption={false}
				/>
			</GroupedFilter>
		</FilterWrapper>
	);
};
export default UsersFilter;
