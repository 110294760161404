import { useEffect, useRef } from 'react';

/**
 * Custom hook for debouncing a callback function.
 *
 * @param {Function} callback The callback function to be debounced.
 * @param {Number} msTime The debounce time in milliseconds.
 * @returns {Function} The debounced callback function.
 * @example
 * import { useState } from 'react';
 * import useDebounce from '@hooks/useDebounce';
 *
 * const DebouncedCountExample = ({}) => {
 * 	const [count, setCount] = useState('0');
 * 	const handleInput = useDebounce(e => setCount(e.target.value), 1000);
 * 	return (
 * 		<>
 * 			<input type="number" onChange={handleInput} />
 * 			<p>Debounced Count: {count}</p>
 * 		</>
 * 	);
 * }
 * export default DebouncedCountExample;
 */
const useDebounce = (callback, msTime) => {
	const timeoutRef = useRef(null);
	useEffect(() => {
		return () => {
			if (!timeoutRef) return;
			clearTimeout(timeoutRef.current);
			timeoutRef.current = null;
		};
	}, []);

	return (...args) => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			callback(...args);
		}, msTime);
	};
};
export default useDebounce;
