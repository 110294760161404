import { useState, useEffect } from 'react';
import { Style, Circle, Fill, Stroke } from 'ol/style';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { IoMove as MoveIcon } from 'react-icons/io5';
import { BiShapeTriangle as AreaIcon } from 'react-icons/bi';
import { IoAnalyticsOutline as DistanceIcon } from 'react-icons/io5';
import { BsEraserFill as ErasorIcon } from 'react-icons/bs';
import { unByKey } from 'ol/Observable';

import { useProject } from '@contexts/Project.context';
import { createOverlay, removeOverlay } from './utils/helpers';
import selectTool from './utils/selectTool';
import { KeyboardShortcut } from '../../SharedStyles';
import useKeyboardShortcut from '@hooks/useKeyboardShortcut.hook';

/**
 * MeasurementTools component, a set of buttons to select the measurement tool.
 *
 * @param {Object} props The props passed to the jsx component containing the tools.
 * @returns {JSX.Element|null} The MeasurementTools component.
 */
const MeasurementTools = ({ ...props }) => {
	const tools = {
		Move: {
			keyName: 'Move',
			shortcutKey: 'M',
			icon: <MoveIcon className="mx-1" />,
			type: 'Move',
		},

		Area: {
			keyName: 'Area',
			shortcutKey: 'A',
			icon: <AreaIcon className="mx-1" />,
			type: 'Polygon',
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.2)',
				}),

				stroke: new Stroke({
					color: '#ffcc33',
					width: 2,
				}),

				image: new Circle({
					radius: 6,
					stroke: new Stroke({
						color: '#ffcc33',
					}),
				}),
			}),
		},

		Distance: {
			keyName: 'Distance',
			shortcutKey: 'D',
			icon: <DistanceIcon className="mx-1" />,
			type: 'LineString',
			style: new Style({
				stroke: new Stroke({
					color: '#ffcc33',
					lineCap: 'round',
					lineJoin: 'bevel',
					width: 2,
				}),

				image: new Circle({
					radius: 4,
					stroke: new Stroke({
						color: '#ffcc33',
					}),
				}),
			}),
		},

		Erase: {
			keyName: 'Erase',
			shortcutKey: 'E',
			icon: <ErasorIcon className="mx-1" />,
			type: 'Erase',
		},
	};
	const { mapObject, measurementLayer } = useProject();

	const [tool, setTool] = useState(tools.Move);
	Object.entries(tools).forEach(([toolName, { shortcutKey, keyName }]) =>
		useKeyboardShortcut(
			shortcutKey,
			measurementLayer?.getVisible() && keyName !== tool.keyName,
			() => setTool(tools[toolName])
		)
	);

	useEffect(() => {
		if (!measurementLayer) return;
		const { type, style } = tool;
		const sketchOverlay = createOverlay({
			mapObject: mapObject,
			type: type,
			className: 'ol-tooltip measurement-sketch',
		});

		const { interactions, keys } = selectTool({
			mapObject: mapObject,
			layer: measurementLayer,
			sketchOverlay: sketchOverlay,
			type: type,
			style: style,
			featureType: 'measurement',
		});

		// Cleanup
		return () => {
			// Removes Sketch Overlay
			removeOverlay({
				mapObject: mapObject,
				overlay: sketchOverlay,
			});

			// Removes Interactions
			interactions.forEach(interaction =>
				mapObject.removeInteraction(interaction)
			);

			// Removes Event Listeners
			keys.forEach(key => unByKey(key));

			// Resets cursor
			mapObject.getTargetElement().style.cursor = 'auto';
		};
	}, [tool, measurementLayer]);

	const [disabled, setDisabled] = useState(false);
	useEffect(() => {
		if (!measurementLayer) return;
		const handleVisibilityChange = () => {
			const visibility = measurementLayer.getVisible();
			setTool(tools.Move);
			setDisabled(!visibility);
		};

		measurementLayer.on('change:visible', handleVisibilityChange);
		return () => {
			measurementLayer.un('change:visible', handleVisibilityChange);
		};
	}, [measurementLayer]);

	if (!mapObject || !measurementLayer) return null;
	return (
		<ToggleButtonGroup
			{...props}
			type="radio"
			name="measurement-tools"
			value={tool.keyName}
			onChange={toolName => setTool(tools[toolName])}>
			{Object.entries(tools).map(
				([toolName, { keyName, shortcutKey, icon }]) => {
					return (
						<ToggleButton
							key={`${toolName}-tool`}
							id={`radio-button-${toolName.toLowerCase()}`}
							disabled={disabled}
							value={toolName}
							variant={
								tool.keyName == keyName ? 'success' : 'dark'
							}>
							{icon}
							{toolName}
							<KeyboardShortcut>
								{shortcutKey.toUpperCase()}
							</KeyboardShortcut>
						</ToggleButton>
					);
				}
			)}
		</ToggleButtonGroup>
	);
};

export default MeasurementTools;
