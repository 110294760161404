import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineMore, AiFillDelete } from 'react-icons/ai';
import { PiRuler } from 'react-icons/pi';

import styled from 'styled-components';

import { useProject } from '@contexts/Project.context';

const DropdownToggle = styled(Dropdown.Toggle)`
	background: none;
	padding: 0 0 3px;
	font-size: 1.2rem;
	:after {
		display: none;
	}
`;

const LayersDropdown = () => {
	const { measurementLayerAdded, dispatch } = useProject();

	const toggleMeasurementLayer = () => {
		dispatch({
			type: 'setMeasurementLayerAdded',
			payload: !measurementLayerAdded,
		});

		if (!measurementLayerAdded) {
			dispatch({
				type: 'setDialogue',
				payload: {
					header: 'Measurement Layer Added',
					body: (
						<>
							<p>Use the draw tools in the bottom toolbar.</p>
							<p>
								Note: The Measurement Layer is temporary and
								will not be saved. Any sketches made here will
								be lost if you refresh the page or remove the
								layer.
							</p>
						</>
					),
					dismissible: true,
				},
			});
		}
	};

	return (
		<Dropdown>
			<DropdownToggle variant="dark">
				<AiOutlineMore />
			</DropdownToggle>
			<Dropdown.Menu variant="dark">
				<Dropdown.Item as="button" onClick={toggleMeasurementLayer}>
					{measurementLayerAdded ? (
						<>
							<AiFillDelete className="mt-1 mb-2 me-2" />
							Remove Measurement Layer
						</>
					) : (
						<>
							<PiRuler className="mt-1 mb-2 me-2" />
							Add Measurement Layer
						</>
					)}
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default LayersDropdown;
