import ProjectLink from './ProjectLink';
import NotificationDate from './NotificationDate';

/**
 * Component to display common content for a notification.
 *
 * @param {string} project_uuid - The UUID of the project.
 * @param {string} project_title - The title of the project.
 * @param {string} model_uuid - The UUID of the model.
 * @param {string} updated_at - The date when the notification was updated.
 * @returns {JSX.Element} The common content including the notification date and project link.
 */
const NotificationContent = ({
	project_uuid,
	project_title,
	model_uuid,
	updated_at,
	disabled,
}) => {
	return (
		<>
			<NotificationDate date={updated_at} />

			{project_uuid && (
				<ProjectLink
					project_uuid={project_uuid}
					project_title={project_title}
					model_uuid={model_uuid}
					disabled={disabled}
				/>
			)}
		</>
	);
};

export default NotificationContent;
