import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Circle, Polygon } from 'ol/geom';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style.js';

import { useProject, ProjectMode } from '@contexts/Project.context';
import { getMasterFeaturesTrainingData } from '@api';
import Checkbox from '../sidebars/sidebarElements/Checkbox';
import { deleteLayerByCustomId, getLayerByCustomId } from '@utils/map/helpers';
import { uniqueValuesBy } from '@utils/helpers';

/*
 *  This component is responsible for rendering the human training data
 */
const TrainingDataLayer = ({ layerId, featureType, setActiveSidebar }) => {
	const {
		mapObject,
		project,
		colorOptions,
		pickedTask,
		trainingDataClassNameInfo,
		projectMode,
		dispatch,
	} = useProject();

	const handleOpenEdit = () => {
		setActiveSidebar();
		trainingDataLayer?.setVisible(true); // We need to see the layer to be able to see what we are editing
	};

	const taskId = pickedTask?.model_uuid;

	const { data, isError, error } = useQuery({
		queryKey: [taskId, 'training_data_master_features', project.uuid],
		queryFn: () =>
			getMasterFeaturesTrainingData(project.uuid, taskId, featureType),
		enabled:
			!!project?.uuid &&
			!!taskId &&
			projectMode === ProjectMode.ORTHOPHOTO,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const makeStyle = ({ color, visible }) => {
		if (!visible) return new Style({});

		const { r, g, b, a } = color || colorOptions[0];
		const colorString = `rgba(${r}, ${g}, ${b}, ${a})`;

		return new Style({
			fill: new Fill({
				color: 'rgba(0, 0, 0, 0.05)',
			}),
			stroke: new Stroke({
				color: colorString,
				width: 2,
			}),
		});
	};

	const adding = useRef(false);
	const [trainingDataLayer, setTrainingDataLayer] = useState(null);

	const removeTrainingDataLayer = () => {
		if (mapObject) {
			const deleted = deleteLayerByCustomId(mapObject, layerId);
			if (deleted) {
				console.log('removed training data layer');
			}
		}

		setTrainingDataLayer(null);
	};

	const updateTrainingDataLayer = useCallback(() => {
		// if the map object exists, task ID and project ID is set, create and add the layer
		if (mapObject && taskId && !adding.current) {
			adding.current = true;
			let layer = null;

			if (!data) {
				adding.current = false;
				return;
			}

			const existingLayer = getLayerByCustomId(mapObject, layerId);

			const { features } = data;

			// get the feature for area of interest from the API
			if (features?.length && features[0]?.geometry) {
				let classNameInfo = [];
				uniqueValuesBy(
					features,
					feature => feature.properties.classname
				).forEach((className, index) => {
					classNameInfo[className] = {
						color: colorOptions[
							index % Object.keys(colorOptions).length
						],
						visible: true,
					};
				});

				dispatch({
					type: 'setTrainingDataClassNameInfo',
					payload: classNameInfo,
				});

				const makeGeometry = feature => {
					const geometry = feature.geometry;
					if (geometry.type === 'Point') {
						return new Circle(
							geometry.coordinates,
							feature.properties.radius
						);
					} else if (geometry.type === 'Polygon') {
						return new Polygon(geometry.coordinates);
					}
				};

				const trainingDataFeatures = features.map(
					feature =>
						new Feature({
							geometry: makeGeometry(feature),
							data: feature.properties,
							classId: feature.properties.classid,
							className: feature.properties.classname,
							confidence: feature.properties.confidence,
						})
				);

				if (existingLayer) {
					console.log(
						`Training Data Layer already exists. Adding it to state.`
					);
					layer = existingLayer;
					const layerSource = layer.getSource();
					// Update source
					layerSource.clear();
					layerSource.addFeatures(trainingDataFeatures);
				} else {
					layer = new VectorLayer({
						zIndex: 12,
						source: new VectorSource({
							features: trainingDataFeatures,
						}),
						name: 'Training data',
						properties: {
							customLayerId: layerId,
						},
						visible: false,
						style: feature =>
							makeStyle(
								classNameInfo[`${feature.get('className')}`]
							),
					});

					// add the layer to the map
					mapObject.addLayer(layer);
					console.log(
						'added training data points layer',
						featureType
					);
				}
			} else if (existingLayer) {
				// No data related to the layer and it is not needed anymore. Delete it.
				deleteLayerByCustomId(mapObject, layerId);
			}

			setTrainingDataLayer(layer);
			adding.current = false;
		}
	}, [data]);

	// Update TrainingDataLayer feature colors
	useEffect(() => {
		if (!trainingDataLayer || !trainingDataClassNameInfo) return;

		trainingDataLayer
			.getSource()
			.getFeatures()
			.forEach(feature => {
				const featureClassName = feature.get('className');
				feature.setStyle(
					makeStyle(trainingDataClassNameInfo[`${featureClassName}`])
				);
			});
	}, [trainingDataLayer, trainingDataClassNameInfo]);

	useEffect(() => {
		updateTrainingDataLayer();

		if (isError) {
			console.warn('could not fetch training data', error);
			removeTrainingDataLayer();
		}

		return () => {
			removeTrainingDataLayer();
		};
	}, [updateTrainingDataLayer, isError]);

	if (!trainingDataLayer) return null;

	return (
		<div id="trainingDataLayer">
			<Checkbox
				label={trainingDataLayer.get('name')}
				handleCheck={() => trainingDataLayer.setVisible(true)}
				handleUncheck={() => trainingDataLayer.setVisible(false)}
				defaultState={false}
				layer={trainingDataLayer}
				handleClick={handleOpenEdit}
			/>
		</div>
	);
};
export default TrainingDataLayer;
