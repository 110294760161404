import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { StyledModal } from '@components/modal/ModalStyle';
import Checkbox from '@components/form/Toggle';
import Input from '@components/form/Input';

import { startPostProcessing } from '@api';

const OdmPostprocessModal = ({ project_uuid, show, setShow }) => {
	const { control, handleSubmit } = useForm({
		defaultValues: {
			image_count: '',
			fastOrtophoto: false,
		},
	});

	const [error, setError] = useState();
	const [success, setSuccess] = useState(false);

	const { mutate: start, loading } = useMutation({
		mutationFn: values => startPostProcessing(values),
		onError: err => {
			console.error('err', err);
			setError(err?.response?.data?.detail ?? 'Unknown error');
			setSuccess(false);
		},
		onSuccess: () => {
			setError(null);
			setSuccess(true);
		},
	});

	const onSubmit = data => {
		setError(null);
		setSuccess(false);

		start({
			...data,
			project_id: project_uuid,
			isOrthophotoProject: true,
			uploadPreprocessedPhoto: false,
		});
	};

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
			onHide={() => {
				setShow(false);
			}}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>ODM Postprocess</Modal.Title>
			</Modal.Header>

			<Form onSubmit={handleSubmit(onSubmit)}>
				<Modal.Body>
					{loading && (
						<div className="d-flex p-2 justify-content-center">
							<Spinner animation="border" variant="white" />
						</div>
					)}

					{success && (
						<p className="alert alert-success">
							Process started successfully
						</p>
					)}

					{error && (
						<p className="alert alert-danger">Error: {error}</p>
					)}

					{!loading && !success && (
						<>
							<Controller
								name="image_count"
								control={control}
								render={({ field }) => (
									<Input
										{...field}
										type="text"
										label="Image count"
										required
									/>
								)}
							/>

							<Controller
								name="fastOrtophoto"
								control={control}
								render={({ field }) => (
									<Checkbox
										{...field}
										label="Fast Orthophoto"
										checked={field.value}
									/>
								)}
							/>
						</>
					)}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Close
					</Button>
					<Button
						variant="success"
						type="submit"
						disabled={loading || success}>
						Run
					</Button>
				</Modal.Footer>
			</Form>
		</StyledModal>
	);
};
export default OdmPostprocessModal;
