import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
} from '@tanstack/react-table';

import EmailVerify from './cells/EmailVerify';

import ApproveUser from './cells/ApproveUser';
import DropdownMenu from './modify/DropdownMenu';
import {
	StyledTable,
	StyledTableHeader,
	StickyTableHeaderWrapper,
} from '@routes/adminRoutes/sharedComponents/SharedStyledComponents';
import CellContent from '@routes/adminRoutes/sharedComponents/CellContent';

import { TIERS } from '@contexts/User.context';

const environment = process.env.NODE_ENV; // Get the current environment
const isProd = environment === 'production'; // Check if the environment is production

const Table = ({ data, loading }) => {
	// Define columns for the table
	const columns = [
		{
			header: 'Email',
			accessorKey: 'email',
			copy: true,
		},
		{
			header: 'Email Verified',
			accessorKey: 'verified',
			cell: ({ row, getValue }) => {
				return (
					<EmailVerify
						cellValue={getValue}
						email={row.original.email}
					/>
				);
			},
		},
		{
			header: 'Approved',
			accessorKey: 'verified_by_admin',
			cell: ({ row, getValue }) => {
				return (
					<ApproveUser
						cellValue={getValue}
						email={row.original.email}
					/>
				);
			},
		},
		{
			header: 'Projects',
			accessorKey: 'projects',
			cell: ({ row }) => {
				return (
					<span>
						{row.original.projects?.length
							? row.original.projects?.length
							: 0}
					</span>
				);
			},
		},
		{
			header: 'Uuid',
			accessorKey: 'uuid',
			copy: true,
		},
		{
			header: 'Tier',
			accessorKey: 'tier',
		},
		{
			header: 'Access',
			accessorKey: 'access',
			cell: ({ row, getValue }) => {
				if (row.original.tier !== TIERS.Premium) {
					return 'All';
				} else {
					return getValue().join(', ');
				}
			},
		},
		{
			header: 'Role',
			accessorKey: 'role',
		},
		{
			header: 'Subscription',
			accessorKey: 'subscription_id',
			cell: ({ getValue }) => {
				const sub = getValue();
				if (sub) {
					const stripeUrl = isProd
						? `https://dashboard.stripe.com/subscriptions/${sub}`
						: `https://dashboard.stripe.com/test/subscriptions/${sub}`;

					return (
						<a href={stripeUrl} target="_blank">
							See in Stripe
						</a>
					);
				}
				return '';
			},
		},
		{
			header: 'Organizations',
			accessorKey: 'organizations',
			cell: ({ row }) => {
				const orgs = row.original.organizations;
				if (orgs && orgs.length > 0) {
					return orgs.join(', ');
				} else {
					return '-';
				}
			},
		},
		{
			header: 'Created',
			accessorKey: 'created_at',
			cell: ({ getValue }) => {
				const date = new Date(getValue());
				return new Intl.DateTimeFormat('nb-NO', {
					day: '2-digit',
					month: '2-digit',
					year: '2-digit',
				}).format(date);
			},
		},
	];

	const table = useReactTable({
		columns,
		data,
		options: {
			manualPagination: true,
		},
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return (
		<StickyTableHeaderWrapper>
			<StyledTable
				borderless
				hover
				responsive
				$loading={loading}
				size="sm">
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(column => {
								return (
									<th key={column.id}>
										<StyledTableHeader>
											{flexRender(
												column.column.columnDef.header,
												column.getContext()
											)}
										</StyledTableHeader>
									</th>
								);
							})}
							<th colSpan="1" role="columnheader">
								<span className="visually-hidden">
									Dropdownmenu
								</span>
							</th>
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => {
						return (
							<tr key={row.id}>
								{row.getVisibleCells().map(cell => {
									return (
										<td
											key={cell.id}
											className="custom-cell">
											<div className={cell.column.id}>
												<CellContent
													cell={cell}
													data={row.original}
												/>
											</div>
										</td>
									);
								})}
								<td role="cell" className="custom-cell">
									<DropdownMenu data={row.original} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</StyledTable>
		</StickyTableHeaderWrapper>
	);
};

export default Table;
