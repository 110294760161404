import NotificationContent from './NotificationContent';
import { Description } from './Layout';
/**
 * Component to display a general notification.
 *
 * @param {Object} notification - The notification object.
 * @returns {JSX.Element} A general notification including common content and message description.
 */
const GeneralNotification = ({ notification }) => {
	const {
		uuid,
		project_uuid,
		project_title,
		model_uuid,
		updated_at,
		message,
	} = notification;

	const textOutput =
		message &&
		Object.values(message).map(value => {
			if (typeof value !== 'string') return null;
			return value;
		});

	return (
		<>
			<NotificationContent
				project_uuid={project_uuid}
				project_title={project_title}
				model_uuid={model_uuid}
				updated_at={updated_at}
			/>

			{textOutput && uuid && (
				<Description>
					{textOutput.map((val, index) => (
						<p key={`${uuid}-${index}`}>{val}</p>
					))}
				</Description>
			)}
		</>
	);
};
export default GeneralNotification;
