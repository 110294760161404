import GeoJSON from 'ol/format/GeoJSON';
import { Point } from 'ol/geom';
import { Feature } from 'ol/index';
import VectorSource from 'ol/source/Vector';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';

import { webGLPointsStyleColor } from './helpers';

export const removePointLayer = async () => {
	return await new Promise(resolve => {
		map.removeLayer(pointLayer);
		map.once('postcompose', resolve);
		pointLayer = null;
	});
};

const createWebGLPointsLayer = async colorScheme => {
	if (
		!pointVectorSource ||
		!features ||
		features.length === 0 ||
		!colorScheme
	)
		return null;

	if (pointLayer) {
		await removePointLayer();
	}

	pointLayer = new WebGLPointsLayer({
		renderBuffer: 1000, // this is set to make sure the points dont dissapear when user zooms in/out. but it may cause performance issues on older browsers
		zIndex: 11,
		source: pointVectorSource,
		name: 'Detection results',
		properties: {
			customLayerId: 'pointsResultsLayer',
		},
		style: {
			'circle-radius': [
				'interpolate',
				['exponential', 2],
				['zoom'],
				5,
				1.5,
				20,
				5,
			],
			'circle-opacity': 1,
			'circle-fill-color': webGLPointsStyleColor(colorScheme, classIds),
		},
	});
	map.addLayer(pointLayer);
};

let pointLayer = null;
let pointVectorSource = null;
let map = null;
let classIds = null;
let features = null;

/**
 * Adds a feature layer to the map
 * @param {Map} currentMap - the map to add the layer to
 * @param {Array} features - the features to add to the layer
 * @param {Object} colorScheme - the color scheme to use for the features
 * @returns {WebGLPointsLayer} the layer
 */
export const makePointLayer = async (
	currentMap,
	defaultProjection,
	feat,
	colorScheme,
	cIds
) => {
	map = currentMap;
	features = feat;
	classIds = cIds;

	if (!features || features.length === 0) return null;
	pointVectorSource = new VectorSource({
		features: features.map(feature => {
			return new Feature({
				geometry: new Point(feature.geometry.coordinates),
				featureType: 'detection',
				data: feature.properties,
				classId: feature.properties.classid,
				className: feature.properties.classname,
				confidence: feature.properties.confidence,
			});
		}),
		format: new GeoJSON({
			defaultDataProjection: defaultProjection,
			defaultFeatureProjection: defaultProjection,
			dataProjection: defaultProjection,
			featureProjection: defaultProjection,
		}),
	});

	await createWebGLPointsLayer(colorScheme);

	return pointLayer;
};
