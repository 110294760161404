import { api } from './index.js';

const convertBytesToMegabytes = bytes => {
	return (bytes / 1024 ** 2).toFixed(0);
};

export const startPostProcessing = async data => {
	const {
		project_id,
		image_count,
		fastOrtophoto,
		isOrthophotoProject,
		uploadPreprocessedPhoto,
		ortophotoSize,
		isSinglePhotoProject,
		exifData,
	} = data;

	let body;

	if (isSinglePhotoProject) {
		body = exifData;
	} else {
		body = uploadPreprocessedPhoto
			? {}
			: {
					fastOrtophoto,
			  };
	}

	try {
		//if uploadPreprocessedPhoto call orthophoto_postproc endpoint, else call odm_postproc endpoint
		const url = isOrthophotoProject
			? uploadPreprocessedPhoto
				? `/biodroneai/orthophoto_postprocessing/${project_id}/${convertBytesToMegabytes(
						ortophotoSize
				  )}`
				: `/biodroneai/odm_postprocessing/${project_id}/${image_count}`
			: `/biodroneai/singleimage_processing/${project_id}`;

		await api.post(url, body);
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const orthophotoPostprocessing = async uuid => {
	try {
		const res = await api.post(
			`/biodroneai/orthophoto_postprocessing/${uuid}`
		);
		return res;
	} catch (err) {
		console.log(err);
		return err.response;
	}
};

export const postDetectPolygon = async (projectId, taskId, results) => {
	const postData = {
		polygon: results[0],
		project_uuid: projectId,
		model_uuid: taskId,
	};
	try {
		const res = await api.post('/biodroneai/predict-in-polygon', postData);
		if (res?.data?.results_file) {
			return res.data;
		} else {
			throw new Error('No results file');
		}
	} catch (err) {
		throw err;
	}
};

export const getPolygonSize = async (polygon, project_uuid, model_uuid) => {
	const postData = {
		polygon: polygon[0],
		project_uuid,
		model_uuid,
	};

	try {
		const res = await api.post('/biodroneai/get_polygon_size', postData);
		return res.data;
	} catch (err) {
		return err;
	}
};
