import { useEffect, useState, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useSearchParams, Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import styled from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import useSetSearchParams from '@hooks/useSetSearchParams.hook';

import AdminView from '@components/layout/views/AdminView';
import Table from './components/OrganizationsTable';
import Toolbar from '@components/toolbar/Toolbar';

import SearchField from '@components/filters/SearchField';
import {
	FilterWrapper,
	FooterText,
} from '@routes/adminRoutes/sharedComponents/SharedStyledComponents';
import Loader from '@components/loading/Loader';
import Toggle from '@components/form/Toggle';

import { getOrganizations } from '@api';

const StyledToggle = styled(Toggle)`
	min-width: 180px;
`;

/*
 * Shows a admin table of users with various filters
 */
const Organizations = () => {
	const [searchParams] = useSearchParams();
	const customSetSearchParams = useSetSearchParams();

	const [showArchived, setShowArchived] = useState(
		searchParams.get('showArchived') === 'true' || false
	);

	/**
	 * Fetch organizations from the API to populate the table
	 */
	const fetchOrganizations = async ({ pageParam = 0 }) => {
		const search = searchParams.get('search');
		const archived = searchParams.get('showArchived');

		return await getOrganizations({
			offset: pageParam ?? 0,
			limit: 100,
			search,
			archived,
		});
	};

	const {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		refetch,
	} = useInfiniteQuery({
		queryKey: ['admin_organizations'],
		queryFn: fetchOrganizations,
		getNextPageParam: (lastPage, allPages) => {
			if (Math.ceil(lastPage.total / lastPage.limit) > allPages.length) {
				return lastPage.offset + lastPage.organizations?.length;
			}
			return undefined;
		},
	});

	const [organizations, total] = useMemo(() => {
		const organizations =
			data?.pages?.flatMap(page => page.organizations) ?? [];
		const total = data?.pages?.[data.pages.length - 1]?.total ?? 0;
		return [organizations, total];
	}, [data]);

	useEffect(() => {
		if (isFetching) return;
		refetch(); // Call refetch() when searchParams change
	}, [searchParams]);

	return (
		<AdminView title="Organizations">
			<FilterWrapper>
				<SearchField
					setSearch={val => {
						customSetSearchParams({ search: val });
					}}
					label="Search by name or uuid"
					searchParamsId="search"
					noWrap
				/>

				<Form>
					<StyledToggle
						id="toggle-archived-filter"
						type="switch"
						onChange={e => {
							customSetSearchParams({
								showArchived: !showArchived,
							});
							setShowArchived(!showArchived);
						}}
						label="Archived"
						checked={showArchived}
						disabled={isFetching}
						variant="dark"
						inline
					/>
				</Form>
			</FilterWrapper>

			<div className="position-relative">
				{isFetching && <Loader />}

				{!isFetching && error && (
					<div className="alert alert-danger" role="alert">
						Something went wrong: {error.message ?? 'Unknown error'}
					</div>
				)}

				{!isFetching && organizations?.length === 0 && (
					<div>No organizations found</div>
				)}

				{!isFetching && organizations?.length > 0 && (
					<>
						<Table data={organizations} loading={isFetching} />

						<div className="col-md-12 mb-5">
							<FooterText>
								Showing {organizations.length} of {total}
							</FooterText>

							{organizations?.length < total && (
								<div className="text-center">
									<Button
										variant="success"
										className="mt-2 mb-2 col-6"
										disabled={
											!hasNextPage || isFetchingNextPage
										}
										onClick={() => fetchNextPage()}>
										<span>Load more</span>{' '}
										{isFetchingNextPage && (
											<Spinner
												animation="border"
												variant="light"
												size="sm"
											/>
										)}
									</Button>
								</div>
							)}
						</div>
					</>
				)}
			</div>

			<Toolbar>
				<Button
					variant="success"
					className="mt-2 mb-2"
					size="sm"
					disabled={isFetching}
					as={Link}
					to={'/admin/organizations/create'}>
					<FaPlus className="mt-1 mb-2 me-2" /> New organization
				</Button>
			</Toolbar>
		</AdminView>
	);
};

export default Organizations;
