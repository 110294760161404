import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { useForm, Controller } from 'react-hook-form';

import { requestPasswordReset } from '@api';

import Loader from '@components/loading/Loader';
import Alert from '@components/alert/Alert';
import View from '@components/layout/views/View';
import Input from '@components/form/Input';

export default function RequestPasswordReset() {
	const { data, mutate, isLoading, isError, isSuccess } = useMutation({
		mutationFn: email => requestPasswordReset(email),
	});

	const { control, handleSubmit } = useForm({
		defaultValues: {
			email: '',
		},
	});

	const onSubmit = data => {
		const { email } = data;
		mutate(email);
	};

	return (
		<View maxWidth={550} title="Reset your password">
			{isLoading && <Loader />}

			{!isLoading && (
				<>
					{isError && (
						<Alert variant="danger">
							<p className="mb-0">
								Unable to send password reset email.
								<br /> Please check if the email address you
								entered is correct.
							</p>
						</Alert>
					)}

					{isSuccess ? (
						<Alert variant="success">
							<p>{data?.message ? <>{data?.message}</> : ''}</p>
							<p className="mb-0">
								Check your email and follow the instructions.
							</p>
						</Alert>
					) : (
						<>
							<Card bg="dark" border="dark" body className="p-2">
								<Form onSubmit={handleSubmit(onSubmit)}>
									<Controller
										name="email"
										control={control}
										render={({ field }) => (
											<Input
												{...field}
												type="email"
												label="Email address"
												placeholder="Enter email"
												required
											/>
										)}
									/>

									<Button variant="success" type="submit">
										Request Password Reset
									</Button>
								</Form>
							</Card>
							<p className="mt-5">
								Remember your old password?{' '}
								<Link to="/login">Log in</Link>
							</p>
						</>
					)}
				</>
			)}
		</View>
	);
}
