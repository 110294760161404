import { useSearchParams } from 'react-router-dom';
import Select from '@components/form/Select';

const SelectField = ({
	label = 'Label',
	setValue,
	searchParamsId = null,
	allOption = true,
	options = [],
	defaultValue = null,
	...props
}) => {
	const [searchParams] = useSearchParams();

	const selectOptions = allOption
		? [{ label: 'All', value: '' }].concat(options)
		: options;
	const valueKey = searchParams.get(searchParamsId) || defaultValue;
	const value = selectOptions.find(option => option.value === valueKey);

	return (
		<div>
			<Select
				label={label}
				onChange={e => {
					setValue(e.value);
				}}
				value={value || ''}
				options={selectOptions}
				noWrap
				{...props}
			/>
		</div>
	);
};

export default SelectField;
