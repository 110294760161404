import styled, { keyframes } from 'styled-components';
const dots = keyframes`
  0% {
    content: ' ';
  }
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
  100% {
    content: '...';
  }
`;

export const LoadingDots = styled.span`
	display: inline-block;
	&::after {
		content: '';
		animation: ${dots} 2s steps(4, end) infinite, pause 2s;
		animation-delay: 0s, 2s;
		min-width: 9px;
		display: inline-block;
	}
`;
export default LoadingDots;
