import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'react-bootstrap';

import Select from '@components/form/Select';
import { filterAndSortSearchParams } from './helpers';

import { getUserTags } from '@utils/api';
import { useAuth } from '@contexts/User.context';

const getSelectedTags = sortedSearchParams => {
	if (!sortedSearchParams?.tags) return;

	const tags = sortedSearchParams.tags.split(',').map((tag, index) => {
		return {
			value: `${tag}-${index}`,
			label: tag,
		};
	});
	return tags;
};

const getTagOptions = (userTags, sortedSearchParams) => {
	const unusedTags = userTags.filter(
		tag => !sortedSearchParams?.tags?.includes(tag)
	);

	const tagOptions = unusedTags.map((tag, index) => {
		return {
			value: `${tag}-${index}`,
			label: tag,
		};
	});

	return tagOptions;
};

const TagsFilter = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const sortedSearchParams = filterAndSortSearchParams(searchParams);

	const { currentUser } = useAuth();

	const {
		data, // All the tags that the user has
		isLoading,
	} = useQuery({
		queryKey: ['user_tags'],
		queryFn: async () => await getUserTags(),
		refetchOnWindowFocus: false,
		retry: false,
		enabled: currentUser?.loggedIn,
	});

	const defaultTags = ['Demo'];
	const userTags = [...(data?.tags || []), ...defaultTags];

	const selectedTags = getSelectedTags(sortedSearchParams);
	const tagOptions = getTagOptions(userTags, sortedSearchParams);

	const onChange = selectedTags => {
		const tags = selectedTags.map(tag => tag.label).toString();
		tags !== ''
			? (sortedSearchParams.tags = tags)
			: delete sortedSearchParams.tags;

		setSearchParams(sortedSearchParams);
	};

	if (isLoading) {
		return (
			<div>
				<Spinner animation="border" variant="light" />
			</div>
		);
	}

	return (
		<div>
			{!userTags || userTags.length === 0 ? (
				<p className="text-muted m-0">
					No tags found. Edit a project to add tags.
				</p>
			) : (
				<Select
					id="project-tags"
					label="Filter by tags"
					value={selectedTags}
					options={tagOptions}
					onChange={onChange}
					isMulti
					noWrap
				/>
			)}
		</div>
	);
};

export default TagsFilter;
