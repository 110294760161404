import { Link } from 'react-router-dom';

/**
 * Component to display a link to a project.
 *
 * @param {string} project_uuid - The UUID of the project.
 * @param {string} project_title - The title of the project. Defaults to 'Unknown Project' if not provided.
 * @param {string} model_uuid - The UUID of the model. If provided, it will be included in the URL as a query parameter.
 * @returns {JSX.Element|null} The project link or null if no project UUID is provided.
 */
const ProjectLink = ({ project_uuid, project_title, model_uuid, disabled }) => {
	if (!project_uuid) return null;

	const title = project_title || 'Unknown Project';

	if (disabled) {
		return <p className="mb-0">{title}</p>;
	}

	const url = model_uuid
		? `/projects/${project_uuid}/?model=${model_uuid}`
		: `/projects/${project_uuid}`;

	return (
		<Link to={url} reloadDocument className="inherit">
			{title}
		</Link>
	);
};
export default ProjectLink;
