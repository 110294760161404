import { getCenter, getHeight, getWidth } from 'ol/extent';

import { uniqueBy } from '@utils/helpers';

/**
 * Helper function to setup the layer details
 *
 * @param {*} features
 * @param {*} colorOptions
 * @returns Object with labels, classIds, and colorStyles
 */
export const setupLayerDetails = (features, colorOptions) => {
	if (features.length === 0) {
		return {
			labels: [],
			classIds: [],
			colorStyles: [],
		};
	}

	const newLabels = [
		...new Set(
			features.map(feature => {
				return feature.properties.classname;
			})
		),
	];

	const newClassIds = [
		...new Set(
			features.map(feature => {
				return feature.properties.classid;
			})
		),
	];

	const modelClasses = uniqueBy(
		features.map(feature => ({
			id: feature.properties.classid,
			label: feature.properties.classname,
		})),
		item => `${item.id}-${item.label}`
	);

	const colorStyles = {};
	newClassIds.forEach((pointId, index) => {
		// Using the index to get the correct color from the colorOptions
		// Using the pointId as the key for the colorStyles object to make it easier to find the color for a given pointId
		colorStyles[pointId] = {
			color: colorOptions[index % Object.keys(colorOptions).length],
			visible: true,
		};
	});

	return {
		modelClasses: modelClasses,
		labels: newLabels,
		classIds: newClassIds,
		colorStyles: colorStyles,
	};
};

export const convertToRgba = color => {
	if (!color) return null;

	const { r, g, b, a } = color;

	return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const webGLPointsStyleColor = (pointsColor, classIds) => {
	if (!classIds || classIds.length === 0 || !pointsColor) return null;

	// if there is only one classId, return the color for that classId. This is to avoid the 'interpolate' style.
	if (classIds.length === 1) {
		if (pointsColor[classIds[0]]?.visible) {
			return convertToRgba(pointsColor[classIds[0]].color);
		} else {
			return 'rgba(255, 255, 255, 0)';
		}
	}

	// if there are more than one classId, return the color for each classId
	const style = ['match', ['get', 'classId']];

	classIds.forEach(classId => {
		style.push(classId);

		if (
			pointsColor[classId]?.visible ||
			pointsColor[classId]?.visible === undefined
		) {
			style.push(
				convertToRgba(pointsColor[classId]?.color) ||
					convertToRgba(pointsColor[classId]?.value) ||
					'rgba(255, 255, 255, )'
			);
		} else {
			style.push('rgba(255, 255, 255, 0)');
		}
	});
	style.push('rgba(255, 255, 255, 0)'); // default color if no match

	return style;
};

export const deleteLayerByCustomId = (mapObject, customLayerId) => {
	if (!mapObject) return;

	const isLayerDeleted = mapObject
		.getLayers()
		.getArray()
		.some(layer => {
			const layerCustomId = layer.getProperties()?.customLayerId;
			if (layerCustomId === customLayerId) {
				mapObject.removeLayer(layer);
				return true;
			}
			return false;
		});

	return isLayerDeleted;
};

export const getLayerByCustomId = (mapObject, customLayerId) => {
	if (!mapObject) return;

	const layer = mapObject
		.getLayers()
		.getArray()
		.find(layer => {
			const layerCustomId = layer.getProperties()?.customLayerId;
			return layerCustomId === customLayerId;
		});

	return layer;
};

export const setLayersVisibilityByCustomIds = (
	mapObject,
	customLayerIds,
	visibility
) => {
	if (!mapObject) return;

	mapObject
		.getLayers()
		.getArray()
		.forEach(layer => {
			const layerCustomId = layer.getProperties()?.customLayerId;
			if (customLayerIds.includes(layerCustomId)) {
				layer.setVisible(visibility);
			}
		});
};

export const addMapLoadingSpinner = map => {
	if (!map) return;
	map.getTargetElement().classList.add('spinner');
};

export const removeMapLoadingSpinner = map => {
	if (!map) return;
	map.getTargetElement().classList.remove('spinner');
};

export const polygonPositionsToMapCoordinates = (
	imagePositionPolygon,
	imageExtent
) => {
	const xLength = imageExtent[2] - imageExtent[0];
	const yLength = imageExtent[3] - imageExtent[1];

	return imagePositionPolygon.map(polygonPositions =>
		polygonPositions.map(position => {
			const xValue = xLength * position[0] + imageExtent[0];
			const yValue = yLength * (1 - position[1]) + imageExtent[1];

			return [xValue, yValue];
		})
	);
};

export const circlePositionToMapCoordinates = (
	imagePositionCircle,
	imageExtent
) => {
	const xLength = imageExtent[2] - imageExtent[0];
	const yLength = imageExtent[3] - imageExtent[1];

	const xValue = xLength * imagePositionCircle[0] + imageExtent[0];
	const yValue = yLength * (1 - imagePositionCircle[1]) + imageExtent[1];

	return [xValue, yValue];
};

export const calculateGeometryCenter = geometry => {
	let center, coordinates, minRadius;
	const type = geometry.getType();
	if (type === 'Polygon') {
		let x = 0;
		let y = 0;
		let i = 0;
		coordinates = geometry.getCoordinates()[0].slice(1);
		coordinates.forEach(function (coordinate) {
			x += coordinate[0];
			y += coordinate[1];
			i++;
		});
		center = [x / i, y / i];
	} else if (type === 'LineString') {
		center = geometry.getCoordinateAt(0.5);
		coordinates = geometry.getCoordinates();
	} else {
		center = getCenter(geometry.getExtent());
	}
	let sqDistances;
	if (coordinates) {
		sqDistances = coordinates.map(function (coordinate) {
			const dx = coordinate[0] - center[0];
			const dy = coordinate[1] - center[1];
			return dx * dx + dy * dy;
		});
		minRadius = Math.sqrt(Math.max.apply(Math, sqDistances)) / 3;
	} else {
		minRadius =
			Math.max(
				getWidth(geometry.getExtent()),
				getHeight(geometry.getExtent())
			) / 3;
	}
	return {
		center: center,
		coordinates: coordinates,
		minRadius: minRadius,
		sqDistances: sqDistances,
	};
};

/**
 * Converts image position polygons to map coordinates.
 *
 * @param {Array} imagePositionPolygon - Array of polygons, where each polygon is an array of [x, y] positions in image coordinates.
 * @param {Array} imageExtent - Array representing the extent of the image in map coordinates [minX, minY, maxX, maxY].
 * @returns {Array} - Array of polygons, where each polygon is an array of [x, y] positions in map coordinates.
 */
export const imagePositionsToMapCoordinates = (
	imagePositionPolygon,
	imageExtent
) => {
	// Calculate the width and height of the image extent
	const xLength = imageExtent[2] - imageExtent[0];
	const yLength = imageExtent[3] - imageExtent[1];

	// Map each polygon in the image position polygon array
	return imagePositionPolygon.map(polygonPositions =>
		// Map each position in the polygon
		polygonPositions.map(position => {
			// Calculate the x and y values in map coordinates
			const xValue = xLength * position[0] + imageExtent[0];
			const yValue = yLength * (1 - position[1]) + imageExtent[1];

			// Return the new position in map coordinates
			return [xValue, yValue];
		})
	);
};

export const preventClickOnOverlay = event => {
	const target = event?.originalEvent?.target;
	if (target.closest('.ol-overlaycontainer')) {
		return true;
	}
	return false;
};
