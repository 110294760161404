import Alert from '@components/alert/Alert';
import Loader from '@components/loading/Loader';
import AlertWarningContent from './AlertWarningContent';

import { convertArea } from '../utils/helpers';

/**
 * Component for displaying the estimated area of the project
 *
 * @param {object} exifFootprint - result of the exif footprint calculation
 * @param {boolean} calculatingArea - whether the area is currently being calculated
 */
const Area = ({ exifFootprint, calculatingArea }) => {
	const {
		footprint_area: estimatedArea,
		warning,
		warning_response: warningResponse,
	} = exifFootprint || {};

	return (
		<Alert variant={warning ? 'warning' : 'dark'} heading="Area">
			{warning ? (
				<AlertWarningContent
					warning={warning}
					warningResponse={warningResponse}
				/>
			) : (
				<>
					{calculatingArea ? (
						<Loader
							inline
							message={
								<>
									Calculating area...
									<br />
									<small>
										Duration varies based on the number of
										images.
									</small>
								</>
							}
						/>
					) : (
						<AlertContent estimatedArea={estimatedArea} />
					)}
				</>
			)}
		</Alert>
	);
};

export default Area;

const AlertContent = ({ estimatedArea }) => (
	<>
		{!estimatedArea && (
			<p className="mb-2">
				After adding files, the area will be calculated.
			</p>
		)}
		{estimatedArea > 0 && (
			<>
				<p className="mb-2">
					Estimated {convertArea(estimatedArea)} hectares for this
					project.
				</p>
				<p className="small text-muted">
					Actual area will be calculated after the project is
					processed.
				</p>
			</>
		)}
	</>
);
